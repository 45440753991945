import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { PreviewService } from '@/services/LinkPreviewService';
import { useBioRSSBlockStore } from '@/stores/Bio/useBioRSSBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faArrowsRotate, faCircle, faRss } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Fieldset,
  Flex,
  Grid,
  Group,
  Loader,
  Slider,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { DEFAULT_RSS_BLOCK_VALUES } from '@replug/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BioStyle } from '../Themes/BioStyle';
import { preview } from 'vite';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { ensureHttps } from '@/utils/LinkUtility';
import { ScheduleSection } from '../ScheduleSection';
import { AnimationSection } from '../AnimationSection';
import { CustomAppearanceSection } from '../CustomAppearanceSection';

export const BioRssSection = ({ reload }: { reload: () => void }) => {
  const { id } = useParams();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [previewLoader, setPreviewLoader] = useState(false);

  const [bioRSSBlock, setBioRSSBlock] = useBioRSSBlockStore((state) => [
    state.bioRSSBlock,
    state.setBioRSSBlock,
  ]);

  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

  const [debounced] = useDebouncedValue(bioRSSBlock.url, 1000);
  // Schedule state variables
  const [isScheduleEnabled, setIsScheduleEnabled] = useState<boolean>(bioRSSBlock.is_schedule_enabled || false);
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    bioRSSBlock.is_schedule_enabled && bioRSSBlock.schedule_start_at ? new Date(bioRSSBlock.schedule_start_at) : null
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    bioRSSBlock.schedule_end_at ? new Date(bioRSSBlock.schedule_end_at) : null
  );
  const [timezone, setTimezone] = useState<string>(bioRSSBlock.schedule_timezone || 'UTC');
  // Animation state variables
  const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(bioRSSBlock.is_animation_enabled || false);
  const [animationType, setAnimationType] = useState<string>(bioRSSBlock.animation_type || 'tada');

  // Custom Appearance state variables
  const [isCustomAppearanceEnabled, setIsCustomAppearanceEnabled] = useState<boolean>(
    bioRSSBlock.is_custom_appearance_enabled || false
  );
  const [customAppearance, setCustomAppearance] = useState(
    bioRSSBlock.custom_appearance_attributes || DEFAULT_RSS_BLOCK_VALUES.custom_appearance_attributes || {
      blockTitleColor: '#242424',
      textColor: '#242424',
      buttonStyle: 'filled',
      buttonBgColor: '#242424',
      buttonTextColor: '#FFFFFF',
      buttonBorderRadius: 10,
      buttonBorderColor: '#242424',
      blockBgColor: '#FFFFFF',
      blockBorderRadius: 10,
      blockBorderColor: '#E9ECEF',
      buttonShadow: 'none',
      blockShadow: 'none'
    }
  );

  const fetchRSSPosts = async () => {
    setPreviewLoader(true);

    await new PreviewService()
      .rss(debounced)
      .then((response) => {
        if (response.data.status && response.data.data) {
          console.log('response.data', response.data);
          setBioRSSBlock({
            ...bioRSSBlock,
            posts: response.data.data,
          });
        } else {
          notifications.show({
            color: 'red',
            message: `Please enter a valid RSS feed, or check the URL`,
          });
        }
      })
      .catch(() => {
        // setBioCardBlock({
        //   ...bioCardBlock,
        //   title: '',
        //   description: '',
        //   image: '',
        // });
      });
    setPreviewLoader(false);
  };

  // Handle add block

  const handleAddBlock = () => {
    if (!bioRSSBlock.url) {
      notifications.show({
        color: 'red',
        message: `Please add a URL for the RSS block`,
      });
      return;
    }

    if (!bioRSSBlock.title && bioRSSBlock.is_show_title_enabled) {
      notifications.show({
        color: 'red',
        message: `Please add a title for the RSS block`,
      });
      return;
    }

    if (bioRSSBlock.posts.length === 0) {
      notifications.show({
        color: 'red',
        message: `Please enter a valid RSS feed, or check the URL`,
      });
      return;
    }

    let payload = {
      ...bioRSSBlock,
      bio_link_id: id,
      workspace_id: defaultWorkspace?._id,
      is_schedule_enabled: isScheduleEnabled,
      schedule_start_at: startDateTime ? startDateTime.toISOString() : null,
      schedule_end_at: endDateTime ? endDateTime.toISOString() : null,
      schedule_timezone: timezone,
      // Animation fields
      is_animation_enabled: isAnimationEnabled,
      animation_type: animationType,
      // Custom Appearance fields
      is_custom_appearance_enabled: isCustomAppearanceEnabled,
      custom_appearance_attributes: customAppearance
    };

    if (bioRSSBlock._id) {
      new BioLinkBlockService()
        .update(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioRSSBlock(DEFAULT_RSS_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400 || err.response.status === 422 || err.response.status === 500) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      payload.order = bioLink?.blocks.length + 1;

      if (!bioLink?._id) {
        notifications.show({
          color: 'red',
          message: 'Please first publish your bio link.',
        });
        return;
      }
      new BioLinkBlockService()
        .create(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioRSSBlock(DEFAULT_RSS_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err: any) => {
          console.log(err);
          if (err.response.status === 400 || err.response.status === 422 || err.response.status === 500) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (
      debounced.length > 0 &&
      debounced.startsWith('http') &&
      bioRSSBlock.posts.length === 0 &&
      !previewLoader
    ) {
      fetchRSSPosts();
    }
  }, [debounced]);

  // Update schedule-related props when schedule settings change
  useEffect(() => {
    setBioRSSBlock({
      ...bioRSSBlock,
      is_schedule_enabled: isScheduleEnabled,
      schedule_start_at: isScheduleEnabled ? (startDateTime || new Date()).toISOString() : null,
      schedule_end_at: isScheduleEnabled ? (endDateTime?.toISOString() || null) : null,
      schedule_timezone: isScheduleEnabled ? timezone : 'UTC'
    });
  }, [isScheduleEnabled, startDateTime, endDateTime, timezone]);

  // Update animation-related props when animation settings change
  useEffect(() => {
    setBioRSSBlock({
      ...bioRSSBlock,
      is_animation_enabled: isAnimationEnabled,
      animation_type: animationType
    });
  }, [isAnimationEnabled, animationType]);

  // Update custom appearance-related props when appearance settings change
  useEffect(() => {
    setBioRSSBlock({
      ...bioRSSBlock,
      is_custom_appearance_enabled: isCustomAppearanceEnabled,
      custom_appearance_attributes: customAppearance
    });
  }, [isCustomAppearanceEnabled, customAppearance]);

  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Display your latest blog posts in a vertical grid. Each post is shown with the title, image,
        and a basic description. You can display up to 5 posts at one time!
      </Text>
      <Divider />
      <Box p="md">
        <Flex mb={'md'} direction={'column'}>
          <Flex
            align={'flex-end'}
            justify={'end'}
            style={{
              flexGrow: 1,
            }}
          >
            <Switch
              size="xs"
              label="Show title"
              checked={bioRSSBlock.is_show_title_enabled}
              onChange={(e) => {
                setBioRSSBlock({ ...bioRSSBlock, is_show_title_enabled: e.currentTarget.checked });
              }}
            />
          </Flex>
          <TextInput
            w={'100%'}
            label="Add a title"
            placeholder="Your title for the RSS block"
            error={bioRSSBlock?.title === '' && bioRSSBlock?.is_show_title_enabled}
            value={bioRSSBlock.title}
            onChange={(e) => {
              setBioRSSBlock({ ...bioRSSBlock, title: e.currentTarget.value });
            }}
          />
        </Flex>
        <Fieldset mb={'md'}>
          <Flex justify={'end'} mt={10} mb={-10}>
            <Switch
              size="xs"
              label="Open link in new tab"
              checked={bioRSSBlock.open_new_tab ?? true}
              onChange={(e) => {
                setBioRSSBlock({
                  ...bioRSSBlock,
                  open_new_tab: e.currentTarget.checked,
                });
              }}
            />
          </Flex>
          <Flex align={'center'}>
            <TextInput
              style={{
                flexGrow: 1,
              }}
              mb={'md'}
              label="RSS URL"
              placeholder="Add your RSS feed URL i.e https://blog.contentstudio.io/feed"
              value={bioRSSBlock.url}
              onChange={(e) => {
                const value = e.currentTarget.value;
                if (value) {
                  setBioRSSBlock({ ...bioRSSBlock, url: ensureHttps(value) });
                } else {
                  setBioRSSBlock({ ...bioRSSBlock, url: e.currentTarget.value });
                }
              }}
              leftSection={
                <>
                  <FontAwesomeIcon icon={faRss} />
                </>
              }
              disabled={previewLoader}
              rightSection={previewLoader && <Loader size="xs" />}
            />
            <ActionIcon
              disabled={previewLoader}
              loading={previewLoader}
              onClick={fetchRSSPosts}
              mt={8}
              variant="outline"
              ml={'sm'}
            >
              <FontAwesomeIcon icon={faArrowsRotate} size="sm" />
            </ActionIcon>
          </Flex>

          <Grid>
            <Grid.Col span={8}>
              <Flex mt={'md'} direction={'column'} justify={'center'} mb="sm">
                <Text fw={500} fz={14} mb={'sm'}>
                  No. of posts to display
                </Text>
                <Slider
                  w={180}
                  color="primary"
                  min={1}
                  step={1}
                  max={10}
                  marks={
                    [
                      { value: 1, label: '1' },
                      { value: 3, label: '3' },
                      { value: 6, label: '6' },
                      { value: 9, label: '9' },
                    ] as any[]
                  }
                  defaultValue={bioRSSBlock.number_of_posts_to_show}
                  value={bioRSSBlock.number_of_posts_to_show}
                  onChange={(value) => {
                    setBioRSSBlock({ ...bioRSSBlock, number_of_posts_to_show: value });
                  }}
                />
              </Flex>
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <BioAnimation />
            </Grid.Col> */}
          </Grid>
          <Grid>
            <Grid.Col span={12} mt={20} mb={10}>
              <BioStyle
                onChange={(value: string) => {
                  setBioRSSBlock({ ...bioRSSBlock, card_style: value });
                }}
                activeStyle={bioRSSBlock.card_style}
              />
            </Grid.Col>
          </Grid>
          <Switch
            mt="lg"
            size="xs"
            checked={bioRSSBlock.is_auto_fetch_enabled}
            onChange={(e) => {
              setBioRSSBlock({ ...bioRSSBlock, is_auto_fetch_enabled: e.currentTarget.checked });
            }}
            label="Automatically fetch RSS feed posts after every 30 minutes."
          />
        </Fieldset>

        {/* Schedule Section */}
        <ScheduleSection
          isScheduleEnabled={isScheduleEnabled}
          setIsScheduleEnabled={setIsScheduleEnabled}
          startDateTime={startDateTime}
          setStartDateTime={setStartDateTime}
          endDateTime={endDateTime}
          setEndDateTime={setEndDateTime}
          timezone={timezone}
          setTimezone={setTimezone}
        />

        {/* Animation Section */}
        <AnimationSection
          isAnimationEnabled={isAnimationEnabled}
          setIsAnimationEnabled={setIsAnimationEnabled}
          animationType={animationType}
          setAnimationType={setAnimationType}
        />

        {/* Custom Appearance Section */}
        <CustomAppearanceSection
          isCustomAppearanceEnabled={isCustomAppearanceEnabled}
          setIsCustomAppearanceEnabled={setIsCustomAppearanceEnabled}
          customAppearance={customAppearance}
          setCustomAppearance={setCustomAppearance}
        />

        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioRSSBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};
