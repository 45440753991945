// components/Bio/ScheduleSection.tsx
import React from 'react';
import { DateTimePicker } from '@mantine/dates';
import { Box, Divider, Fieldset, Flex, Grid, Select, Switch, Text } from '@mantine/core';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TIMEZONES } from '@/constants/Constants';

interface ScheduleSectionProps {
    isScheduleEnabled: boolean;
    setIsScheduleEnabled: (value: boolean) => void;
    startDateTime: Date | null;
    setStartDateTime: (date: Date | null) => void;
    endDateTime: Date | null;
    setEndDateTime: (date: Date | null) => void;
    timezone: string;
    setTimezone: (value: string) => void;
}

export const ScheduleSection: React.FC<ScheduleSectionProps> = ({
    isScheduleEnabled,
    setIsScheduleEnabled,
    startDateTime,
    setStartDateTime,
    endDateTime,
    setEndDateTime,
    timezone,
    setTimezone
}) => {
    return (
        <>
            <Fieldset mb={'md'}>
                <Flex align="center" mb={16}>
                    <Text style={{ flexGrow: 1, fontSize: '15.5px' }} fw={500}>
                        <Box component="span" style={{ display: "flex", alignItems: "center" }}>
                            <FontAwesomeIcon icon={faClock} style={{ marginRight: 8 }} />
                            Schedule
                        </Box>
                    </Text>
                    <Switch
                        size="sm"
                        checked={isScheduleEnabled}
                        onChange={(e) => {
                            const enabled = e.currentTarget.checked;
                            setIsScheduleEnabled(enabled);
                            if (enabled && !startDateTime) {
                                setStartDateTime(new Date());
                            } else if (!enabled) {
                                // Clear all dates when schedule is disabled
                                setStartDateTime(null);
                                setEndDateTime(null);
                            }
                        }}
                    />
                </Flex>

                {isScheduleEnabled && (
                    <>
                        <Select
                            mb={16}
                            label="Timezone"
                            placeholder="Select a timezone"
                            data={TIMEZONES}
                            value={timezone}
                            onChange={(value) => setTimezone(value || 'UTC')}
                            searchable
                            //@ts-ignore
                            nothingFound="No options found"
                        />

                        <Grid grow>
                            <Grid.Col span={6}>
                                <Text fw={500} fz="sm" mb={8}>Start at</Text>
                                <DateTimePicker
                                    placeholder="Pick date and time"
                                    value={startDateTime}
                                    onChange={setStartDateTime}
                                    minDate={new Date()}
                                    disabled={!isScheduleEnabled}
                                    clearable={false}
                                    valueFormat="DD MMM YYYY hh:mm A"
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text fw={500} fz="sm" mb={8}>End at</Text>
                                <DateTimePicker
                                    placeholder="Pick date and time"
                                    value={endDateTime}
                                    onChange={setEndDateTime}
                                    minDate={startDateTime || new Date()}
                                    disabled={!isScheduleEnabled || !startDateTime}
                                    clearable
                                    valueFormat="DD MMM YYYY hh:mm A"
                                />
                            </Grid.Col>
                        </Grid>
                    </>
                )}
            </Fieldset>
        </>
    );
};