import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { LinksService } from '@/services/LinksService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useLinksStore } from '@/stores/useLinksStore';
import { useTagsStore } from '@/stores/useTagsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { getShortenedURL } from '@/utils/LinkUtility';
import { faEllipsis, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Checkbox,
  Flex,
  Menu,
  Pill,
  Switch,
  Table,
  Text,
  Title,
  useMantineTheme,
  Space
} from '@mantine/core';
import { useClipboard, useDebouncedValue } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LinkComponent } from './LinkComponent';
import classes from './Links.module.css';
import { findCampaignType } from '@/utils/CampaignUtility';
import { LinkCreationModal } from '@/components/Modals/LinkCreationModal/LinkCreationModal';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { LinkTagsComponent } from './LinkTagsComponent';
import { ILink } from '@/types/links';
import { numberToCommas } from '@/utils/StringUtility';
import { QRCodeModal } from '@/components/Modals/QRCodeModal/QRCodeModal';
import { useQRModalStore } from '@/stores/useQRModalStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';

export function LinksTable({
  theadBg = 'gray.1',
  excludeColumns = false,
}: {
  theadBg?: string;
  excludeColumns?: boolean;
}) {
  // Various hooks and state variables for managing links, tags, campaigns, workspace, etc.
  // useParams hook is used to get the workspaceId from the URL
  const { workspaceId } = useParams();
  const [
    archive,
    setArchive,
    date,
    setDate,
    search,
    setSearch,
    page,
    setPage,
    setLastPage,
    totalResults,
  ] = useLinksStore((state) => [
    state.archive,
    state.setArchive,
    state.date,
    state.setDate,
    state.search,
    state.setSearch,
    state.page,
    state.setPage,
    state.setLastPage,
    state.totalResults,
  ]);

  const [setQRModalOpened, setQRModalURL, setQRModalProps, setFgColor, setBgColor] =
    useQRModalStore((state) => [
      state.setIsOpen,
      state.setURL,
      state.setProps,
      state.setFgColor,
      state.setBgColor,
    ]);

  const [debounced] = useDebouncedValue(search, 200);
  // Tags store
  const [tags, selectedTags, setSelectedTags] = useTagsStore((state) => [
    state.tags,
    state.selectedTags,
    state.setSelectedTags,
  ]);

  // Campaigns store
  const [selectedCampaigns, setSelectedCampaigns] = useCampaignsStore((state) => [
    state.selectedCampaigns,
    state.setSelectedCampaigns,
  ]);

  // Workspace store
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  // States for this component

  const [links, setLinks] = useState<ILink[]>([]);
  const [setLink, linkModalOpen, setLinkModalOpen] = useLinkSetupStore((state) => [
    state.setLink,
    state.openedModal,
    state.setOpenedModal,
  ]);
  const [loading, setLoading] = useState(true);
  const theme = useMantineTheme();
  const clipboard = useClipboard();

  const navigate = useNavigate();
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const latestRequestRef = useRef(null);

  /**
   * Fetches links from the backend.
   * Uses LinksService to make an API call and updates the links state.
   */
  const fetchLinks = async () => {
    setLoading(true);
    const request:any = new LinksService()
      .get(
        defaultWorkspace?._id || '',
        selectedCampaigns,
        selectedTags,
        page,
        debounced,
        'created_at:desc',
        totalResults,
        archive,
        date.value
      )
      .then((res) => {
        if (latestRequestRef.current === request) {
          if (res.data.status) {
            setLinks(res.data.data.data);
            setLastPage(res.data.data.last_page);
          }
        }
      })
      .catch((res) => {
        console.log(res);
      })
      .finally(() => {
        if (latestRequestRef.current === request) {
          setLoading(false);
        }
      });

    latestRequestRef.current = request;
  };

  /**
   * Handles the status change of a link.
   * Toggles the link status between active and inactive.
   * @param link - The link object whose status is to be changed.
   * @param status - The current status of the link.
   */
  const handleChangeStatus = (link: ILink, status: boolean) => {
    if (link.blacklisted_url) {
      notifications.show({
        color: 'red',
        message: 'Link is marked as blacklisted.',
      });
      return;
    }
    new LinksService()
      .changeStatus(defaultWorkspace?._id || '', link._id, !status)
      .then((res) => {
        if (res.data.status) {
          const updatedLinks = links.map((item: ILink) => {
            if (item._id === link._id) {
              return { ...item, is_link_disabled: !status, status: status }; // Create a new object with the updated status
            }
            return item;
          });
          setLinks(updatedLinks); // Set the new array as the new state
          notifications.show({
            color: 'green',
            message: res?.data?.message,
          });
        } else {
          notifications.show({
            color: 'red',
            message: res?.data?.message,
          });
        }
      })
      .catch((err) => {
        if (!err.response.data.status) {
          notifications.show({
            color: 'red',
            message: err?.response?.data?.message,
          });
        }
      });
  };

  /**
   * Handles the deletion of a link.
   * @param id - The id of the link to be deleted.
   */
  const handleDeleteLink = async (id: string) => {
    await new LinksService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          const updatedLinks = links.filter((item: ILink) => item._id !== id);
          setLinks(updatedLinks);
          notifications.show({
            color: 'green',
            message: 'Link deleted successfully.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a link.
   * @param id - The id of the link to be deleted.
   */
  const confirmLinkDeletion = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Link</Title>,
      children: (
        <>
          <Text size="sm">
            Are you sure you want to delete your link? This action is irrervrsible.
          </Text>
        </>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteLink(id),
    });
  };

  /**
   * Sends an API call to the backend to archive a link.
   * @param id - The id of the link to be archived.
   */
  const handleArchiveLink = async (id: string) => {
    await new LinksService()
      .archive(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          const updatedLinks = links.filter((item: ILink) => item._id !== id);
          setLinks(updatedLinks);
          notifications.show({
            color: 'green',
            message: 'Link archived successfully.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Sends an API call to the backend to unarchive a link.
   * @param id - The id of the link to be unarchived.
   */
  const handleUnarchiveLink = async (id: string) => {
    await new LinksService()
      .unarchive(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          const updatedLinks = links.filter((item: ILink) => item._id !== id);
          setLinks(updatedLinks);
          notifications.show({
            color: 'green',
            message: 'Link unarchived successfully.',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Confirms the archiving of a link.
   * @param id - The id of the link to be archived.
   */
  const confirmArchiveLink = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Archive Link</Title>,
      children: (
        <>
          <Text size="sm">
            Do you want to archive ? Link will be archived but it will still be functional.
          </Text>
        </>
      ),
      labels: { confirm: 'Archive', cancel: 'Cancel' },
      confirmProps: { color: 'orange' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleArchiveLink(id),
    });
  };

  const handleEditLink = async (id: string) => {
    console.log('Edit link', id);
    await new LinksService()
      .getById(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          setLink(res.data.data);
          setLinkModalOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect hooks for performing side effects like fetching links and managing state based
  // on URL parameters and component lifecycle
  useEffect(() => {
    if (!linkModalOpen && date?.value) {
      fetchLinks();
    }
    // Dependency array includes variables that trigger re-fetching when they change
  }, [totalResults, page, selectedTags, selectedCampaigns, archive, date, debounced, linkModalOpen]);

  // useEffect for cleanup when the component unmounts or workspaceId changes
  useEffect(() => {
    if (links.length > 0) {
      // Reset
      setArchive(false);
      setSelectedCampaigns([]);
      setSelectedTags([]);
      setLinks([]);
      setSearch('');
      setDate({
        label: 'All time',
        value: '',
      });
      setPage(1);
    }
    return () => {
      setArchive(false);
      setPage(1);
      setSelectedCampaigns([]);
      setSelectedTags([]);
      setLinks([]);
      setSearch('');
      setDate({
        label: 'All time',
        value: '',
      });
    };
  }, [workspaceId]);

  return (
    <>
      <Table stickyHeader verticalSpacing={'md'}>
        <Table.Thead h={20} bg={theadBg} c={'gray.7'} fz="xs">
          <Table.Tr>
            {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) ? <Table.Th /> : <Table.Th />}

            <Table.Th w={'40%'}>Link</Table.Th>
            <Table.Th>Campaign</Table.Th>
            <Table.Th ta={'center'}>Clicks</Table.Th>
            <Table.Th ta="center">Unique Clicks</Table.Th>
            <Table.Th ta="center">QR Scans</Table.Th>
            {(defaultPermissions && checkPermission(defaultPermissions, ['view_link'])) ? <Table.Th ta="center">Tags</Table.Th> : <Table.Th />}
            {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) ? <Table.Th ta="center">Status</Table.Th> : <Table.Th />}
            <Table.Th ta={'center'}>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <>
              <LoaderDataTable colSpan={excludeColumns ? 7 : 9} />
            </>
          ) : (
            <>
              <NoResultsDataTable
                data={links}
                noResultsMessage="No links available"
                colSpan={excludeColumns ? 7 : 9}
              />
              {links.map((link: ILink, index: number) => (
                <Table.Tr
                  key={link._id}
                  className={
                    !link.status || link?.is_archived  || link.blacklisted_url ? classes.linkDisabled : undefined
                  }
                >
                  {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') ? (
                    <Table.Td>
                      <Checkbox variant="filled" size="xs" />
                    </Table.Td>
                  ) : <Space h="md" />}

                  <Table.Td style={{ maxWidth: '30vw' }}>
                    <LinkComponent link={link} />
                  </Table.Td>
                  <Table.Td>
                    <Flex direction={'column'}>
                      <Text pb={4} fz={13}>
                        {link?.campaign?.name}
                      </Text>
                      <Badge size="xs" color="primary.1">
                        {findCampaignType(link?.campaign)}
                      </Badge>
                    </Flex>
                  </Table.Td>
                  <Table.Td ta="center">
                    <Text fz={14} fw={500}>
                      {numberToCommas(link.clicks)}
                    </Text>
                  </Table.Td>
                  <Table.Td ta="center">
                    <Text fz={14} fw={500}>
                      {numberToCommas(link.unique_clicks)}
                    </Text>
                  </Table.Td>
                  <Table.Td ta="center">
                    <Text fz={14} fw={500}>
                      {numberToCommas(link.qr_scans)}
                    </Text>
                  </Table.Td>
                  {(defaultPermissions && checkPermission(defaultPermissions, ['view_link'])) ? (
                    <Table.Td ta="center" maw={200}>
                      <LinkTagsComponent
                        link={link}
                        show={(defaultPermissions && checkPermission(defaultPermissions, ['edit_link']) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link'))}
                        onChange={(tags: any) => {
                          // find the link in the links array and update the tags
                          const updatedLinks = links.map((item: ILink) => {
                            if (item._id === link._id) {
                              return { ...item, tags }; // Create a new object with the updated status
                            }
                            return item;
                          });
                          setLinks(updatedLinks); // Set the new array as the new state
                        }}
                      />
                    </Table.Td>
                  ) : <Space h="md" />}

                  {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link']) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link')) ? <Table.Td ta="center">
                    <Center>
                      <Switch
                        style={{
                          cursor: 'pointer',
                        }}
                        key={link._id}
                        onLabel="ON"
                        offLabel="OFF"
                        checked={!link.is_link_disabled ? link.status : false}
                        onChange={(event) => handleChangeStatus(link, event.currentTarget.checked)}
                        size={'sm'}
                      />
                    </Center>
                  </Table.Td> : <Space h="md" />}
                  <Table.Td>
                    {!archive ? <Menu withArrow withinPortal>
                      <Menu.Target>
                        <Center>
                          <ActionIcon c="gray" variant="subtle">
                            <FontAwesomeIcon icon={faEllipsis} />
                          </ActionIcon>
                        </Center>
                      </Menu.Target>
                      <Menu.Dropdown w="150">
                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Item onClick={() => handleEditLink(link._id)}>Edit</Menu.Item>}
                        {!link.is_archived && (
                          <>
                            <Menu.Item
                              onClick={() => {
                                const url = `/analytics/${defaultWorkspace?._id}/short-links?campaign_id=${link.campaign_id}&link_id=${link._id}`;
                                navigate(url);
                              }}
                            >
                              View Stats
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                clipboard.copy(getShortenedURL(link));
                                notifications.show({
                                  color: 'green',
                                  title: 'Copied to clipboard',
                                  message: 'You can now paste it anywhere',
                                });
                              }}
                            >
                              Copy URL
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                setQRModalURL(getShortenedURL(link));
                                setQRModalProps(link);
                                setFgColor('#242424');
                                setBgColor('#FFFFFF');
                                setQRModalOpened(true);
                              }}
                            >
                              QR Code
                            </Menu.Item>
                          </>
                        )}

                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Item
                          onClick={() => {
                            if (link?.is_archived) {
                              handleUnarchiveLink(link._id);
                            } else {
                              confirmArchiveLink(link._id);
                            }
                          }}
                        >
                          {link?.is_archived ? 'Unarchive' : 'Archive'}
                        </Menu.Item>}
                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Divider />}
                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Item onClick={() => confirmLinkDeletion(link._id)} c={'red'}>
                          Delete
                        </Menu.Item>}
                      </Menu.Dropdown>
                    </Menu> : (isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu withArrow withinPortal>
                      <Menu.Target>
                        <Center>
                          <ActionIcon c="gray" variant="subtle">
                            <FontAwesomeIcon icon={faEllipsis} />
                          </ActionIcon>
                        </Center>
                      </Menu.Target>
                      <Menu.Dropdown w="150">
                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Item onClick={() => handleEditLink(link._id)}>Edit</Menu.Item>}
                        {!link.is_archived && (
                          <>
                            <Menu.Item
                              onClick={() => {
                                const url = `/analytics/${defaultWorkspace?._id}/short-links?campaign_id=${link.campaign_id}&link_id=${link._id}`;
                                navigate(url);
                              }}
                            >
                              View Stats
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                clipboard.copy(getShortenedURL(link));
                                notifications.show({
                                  color: 'green',
                                  title: 'Copied to clipboard',
                                  message: 'You can now paste it anywhere',
                                });
                              }}
                            >
                              Copy URL
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                setQRModalURL(getShortenedURL(link));
                                setQRModalProps(link);
                                setFgColor('#242424');
                                setBgColor('#FFFFFF');
                                setQRModalOpened(true);
                              }}
                            >
                              QR Code
                            </Menu.Item>
                          </>
                        )}

                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Item
                          onClick={() => {
                            if (link?.is_archived) {
                              handleUnarchiveLink(link._id);
                            } else {
                              confirmArchiveLink(link._id);
                            }
                          }}
                        >
                          {link?.is_archived ? 'Unarchive' : 'Archive'}
                        </Menu.Item>}
                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Divider />}
                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && isUserCollaborator(user, defaultWorkspace?._id, link, 'link') && <Menu.Item onClick={() => confirmLinkDeletion(link._id)} c={'red'}>
                          Delete
                        </Menu.Item>}
                      </Menu.Dropdown>
                    </Menu>)}
                  </Table.Td>
                </Table.Tr>
              ))}
            </>
          )}
        </Table.Tbody>
      </Table>
    </>
  );
}
