import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Combobox,
  Flex,
  Grid,
  InputBase,
  Select,
  Stack,
  useCombobox
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useMantineTheme } from '@mantine/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  endOfDay,
  format,
  startOfDay,
  subDays,
  subMonths,
  subYears,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  subHours,
} from "date-fns";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import { useMediaQuery } from '@mantine/hooks';
import { formatInTimeZone } from 'date-fns-tz';

interface DatePickerProps {
  showButtons: {
    all_time: boolean;
    last_24_hours: boolean;
    last_7_days: boolean;
    last_14_days: boolean;
    last_30_days: boolean;
    last_90_days: boolean;
    last_6_months: boolean;
    last_1_year: boolean;
    this_month: boolean;
    last_month: boolean;
    "7D": boolean;
    "14D": boolean;
    "30D": boolean;
    "3M": boolean;
    "6M": boolean;
    "12M": boolean;
    "no-range": boolean;
    custom: boolean;
  };
  onDateChange: (
    startDate: string | null,
    endDate: string | null,
    granularity: "hour" | "day" | "week" | "month" | "all"
  ) => void;
  value: [string | null, string | null];
  granularity?: "hour" | "day" | "week" | "month" | "all";
  disabled?: boolean;
  withGranularity?: boolean;
}

function formatDateRange(value: string) {
  let startDate, endDate;
  endDate = new Date();

  switch (value) {
    case 'last_24_hours':
      startDate = subHours(endDate, 24);
      break;
    case 'last_7_days':
    case '7D':
      startDate = subDays(endDate, 7);
      break;
    case 'last_14_days':
    case '14D':
      startDate = subDays(endDate, 14);
      break;
    case 'last_30_days':
    case '30D':
      startDate = subMonths(endDate, 1);
      break;
    case 'last_90_days':
    case '3M':
      startDate = subMonths(endDate, 3);
      break;
    case 'last_6_months':
    case '6M':
      startDate = subMonths(endDate, 6);
      break;
    case 'last_1_year':
    case '12M':
      startDate = subYears(endDate, 1);
      break;
    case 'this_month':
      startDate = startOfMonth(new Date());
      endDate = new Date();
      break;
    case 'last_month':
      startDate = startOfMonth(subMonths(new Date(), 1));
      endDate = endOfMonth(subMonths(new Date(), 1));
      break;
    default:
      return [null, null];
  }

  return [
    format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
    format(endDate, "yyyy-MM-dd'T'HH:mm:ss")
  ];
}

export const CustomDateMenu: React.FC<DatePickerProps> = ({
  showButtons,
  onDateChange,
  value,
  disabled = false,
  withGranularity = false,
  granularity = "day"
}) => {
  const theme = useMantineTheme();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active")
  });

  const [customDateRange, setCustomDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [selectedOption, setSelectedOption] = useState<string | null>("all_time");
  const isMobile = useMediaQuery('(max-width: 48em)');

  const [internalGranularity, setInternalGranularity] = useState<
    "hour" | "day" | "week" | "month" | "all"
  >(granularity);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState<number | null>(null);
  // Add workspace store
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const handleRangeSelect = (start: Date, end: Date) => {
    const startStr = format(startOfDay(start), "yyyy-MM-dd'T'HH:mm:ss");
    const endStr = format(endOfDay(end), "yyyy-MM-dd'T'HH:mm:ss");
    onDateChange(startStr, endStr, internalGranularity);
    setSelectedOption("custom");
    combobox.closeDropdown();
  };


  // Enhanced options array combining both original menus
  const options = [
    {
      label: "All time",
      value: "all_time",
      onClick: () => {
        const createdDate = formatInTimeZone(
          new Date(defaultWorkspace?.created_at || ''),
          'UTC',
          "yyyy-MM-dd'T'HH:mm:ssXXX"
        );
        const endDate = endOfDay(new Date());
        onDateChange(
          format(createdDate, "yyyy-MM-dd'T'HH:mm:ss"),
          format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
          internalGranularity
        );
        setSelectedOption("all_time");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last 24 hours",
      value: "last_24_hours",
      onClick: () => {
        const [start, end] = formatDateRange("last_24_hours");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_24_hours");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last 7 days",
      value: "last_7_days",
      onClick: () => {
        const [start, end] = formatDateRange("last_7_days");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_7_days");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last 14 days",
      value: "last_14_days",
      onClick: () => {
        const [start, end] = formatDateRange("last_14_days");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_14_days");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last 30 days",
      value: "last_30_days",
      onClick: () => {
        const [start, end] = formatDateRange("last_30_days");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_30_days");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last month",
      value: "last_month",
      onClick: () => {
        const [start, end] = formatDateRange("last_month");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_month");
        combobox.closeDropdown();
      }
    },
    {
      label: "This month",
      value: "this_month",
      onClick: () => {
        const [start, end] = formatDateRange("this_month");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("this_month");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last 90 days",
      value: "last_90_days",
      onClick: () => {
        const [start, end] = formatDateRange("last_90_days");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_90_days");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last 6 months",
      value: "last_6_months",
      onClick: () => {
        const [start, end] = formatDateRange("last_6_months");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_6_months");
        combobox.closeDropdown();
      }
    },
    {
      label: "Last 1 year",
      value: "last_1_year",
      onClick: () => {
        const [start, end] = formatDateRange("last_1_year");
        onDateChange(start, end, internalGranularity);
        setSelectedOption("last_1_year");
        combobox.closeDropdown();
      }
    },
    // Add these if you want to keep the old format options as well
    {
      label: "Last 7 days",
      value: "7D",
      onClick: () => {
        const [start, end] = formatDateRange("last_7_days");
        onDateChange(start, end, internalGranularity);
      }
    },
    {
      label: "Last 14 days",
      value: "14D",
      onClick: () => {
        const [start, end] = formatDateRange("last_14_days");
        onDateChange(start, end, internalGranularity);
      }
    },
    {
      label: "Last 30 days",
      value: "30D",
      onClick: () => {
        const [start, end] = formatDateRange("last_30_days");
        onDateChange(start, end, internalGranularity);
      }
    },
    {
      label: "Last 90 days",
      value: "3M",
      onClick: () => {
        const [start, end] = formatDateRange("last_90_days");
        onDateChange(start, end, internalGranularity);
      }
    },
    {
      label: "Last 6 months",
      value: "6M",
      onClick: () => {
        const [start, end] = formatDateRange("last_6_months");
        onDateChange(start, end, internalGranularity);
      }
    },
    {
      label: "Last 12 months",
      value: "12M",
      onClick: () => {
        const [start, end] = formatDateRange("last_1_year");
        onDateChange(start, end, internalGranularity);
      }
    }
  ].filter((option) => showButtons[option.value as keyof typeof showButtons]);

  const formatDisplayDate = (date: Date) => {
    return format(date, 'MMM d, yyyy');
  };

  const displayText = () => {
    if (selectedOption === "no-range") {
      return "No Date Range";
    }

    // If custom date range is selected
    if (selectedOption === "custom" && customDateRange[0] && customDateRange[1]) {
      return `${formatDisplayDate(customDateRange[0])} - ${formatDisplayDate(customDateRange[1])}`;
    }

    // For predefined options, show their labels
    if (selectedOption) {
      const selectedOptionData = options.find(opt => opt.value === selectedOption);
      if (selectedOptionData) {
        return selectedOptionData.label;
      }
    }

    // Default text
    return "Select date range";
  };

  // Effect to calculate duration and handle granularity constraints
  useEffect(() => {
    const [start, end] = customDateRange;
    if (start && end) {
      const days = differenceInDays(end, start);
      setDuration(days);

      if (days > 7 && internalGranularity === "hour") {
        setInternalGranularity("day");
        onDateChange(
          format(start, "yyyy-MM-dd'T'HH:mm:ss"),
          format(end, "yyyy-MM-dd'T'HH:mm:ss"),
          "day"
        );
      }
    }
  }, [customDateRange, internalGranularity]);

  useEffect(() => {
    // Reset custom date range when any predefined option is selected
    if (selectedOption && selectedOption !== 'custom') {
      setCustomDateRange([null, null]);
    }
  }, [selectedOption]);

  // Add this useEffect right after your state declarations
  useEffect(() => {
    console.log('Original created_at:', defaultWorkspace?.created_at);
    
    // Parse and maintain UTC timezone
    const createdDate = defaultWorkspace?.created_at 
      ? formatInTimeZone(
          new Date(defaultWorkspace.created_at),
          'UTC',  // Explicitly use UTC
          "yyyy-MM-dd'T'HH:mm:ss"  // Simplified format
        )
      : new Date();
      
    const endDate = formatInTimeZone(
      endOfDay(new Date()),
      'UTC',  // Explicitly use UTC
      "yyyy-MM-dd'T'HH:mm:ss"  // Simplified format
    );
  
    console.log('Formatted created date:', createdDate);
    console.log('Formatted end date:', endDate);
  
    // No need for additional formatting since we're already in the correct format
    //@ts-ignore
    onDateChange(createdDate, endDate, internalGranularity);
  
  }, []);

  const granularityOptions = [
    { label: "Hour", value: "hour", disabled: duration !== null && duration > 7 },
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" }
  ];

  return (
    <Flex gap="xs">
      <Combobox
        store={combobox}
        withinPortal={true}
        width={430}
        position="bottom-start"
        disabled={disabled}>
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            pointer
            size="xs"  // Add this
            rightSectionPointerEvents="none"
            onClick={() => !disabled && combobox.toggleDropdown()}
            disabled={disabled}
            leftSection={
              <FontAwesomeIcon
                icon={faCalendar}
                size="xs"
                style={{ color: '#212529' }}
              />
            }
            rightSection={
              <Combobox.Chevron
                style={{ color: '#212529' }}
              />
            }
            styles={{
              root: {
                width: '100%',
                maxWidth: isMobile ? '100%' : '430px',
              },
              input: {
                backgroundColor: 'transparent',
                border: '1px solid #212529',
                fontSize: '12px',
                fontWeight: 500,
                padding: '0px 32px',
                height: '32px',
                minHeight: '32px',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}>
            {displayText()}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Grid gutter="md">
            <Grid.Col span={4}>
              <Stack gap="2px">
                {options.map((option) => (
                  <Combobox.Option
                    value={option.value}
                    key={option.value}
                    onClick={option.onClick}
                    // selected={selectedOption === option.value}
                    style={{
                      backgroundColor: selectedOption === option.value ? '#e0e7f5' : 'transparent',
                      color: selectedOption === option.value ? '#003aa9' : '#212529',
                      '&:hover': {
                        backgroundColor: selectedOption === option.value ? '#e7f5ff' : '#f1f3f5'
                      }
                    }}
                  >
                    {option.label}
                  </Combobox.Option>
                ))}
              </Stack>
            </Grid.Col>
            {showButtons.custom && (
              <Grid.Col span={8}>
                <Box ref={datePickerRef}>
                  <DatePicker
                    maxDate={new Date()}
                    type="range"
                    allowSingleDateInRange={false}
                    value={customDateRange}
                    onChange={setCustomDateRange}
                  />
                </Box>
                <Button
                  mt="sm"
                  size="xs"
                  fullWidth
                  variant="filled"
                  onClick={() => {
                    if (customDateRange[0] && customDateRange[1]) {
                      handleRangeSelect(customDateRange[0], customDateRange[1]);
                    }
                  }}>
                  Apply Custom Range
                </Button>
              </Grid.Col>
            )}
          </Grid>
        </Combobox.Dropdown>
      </Combobox>

      {withGranularity && selectedOption !== "no-range" && (
        <Select
          disabled={disabled}
          data={granularityOptions}
          value={internalGranularity}
          onChange={(newValue) => {
            const newGranularity = newValue as "hour" | "day" | "week" | "month" | "all";
            setInternalGranularity(newGranularity);
            if (value[0] && value[1]) {
              onDateChange(value[0], value[1], newGranularity);
            }
          }}
          allowDeselect={false}
        />
      )}
    </Flex>
  );
};

export default CustomDateMenu;
