// File: src/pages/BioLinks/Components/Sections/BioSpacingSection.tsx

import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useBioSpacingBlockStore } from '@/stores/Bio/useBioSpacingBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
    Box,
    Button,
    Center,
    Divider,
    Fieldset,
    Group,
    Slider,
    Stack,
    Text,
    Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ScheduleSection } from '../ScheduleSection';
import { AnimationSection } from '../AnimationSection';
import { CustomAppearanceSection } from '../CustomAppearanceSection';

export const BioSpacingSection = ({ reload }: { reload: () => void }) => {
    const { id } = useParams();
    const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

    const [bioSpacingBlock, setBioSpacingBlock, resetBioSpacingBlock] = useBioSpacingBlockStore((state) => [
        state.bioSpacingBlock,
        state.setBioSpacingBlock,
        state.reset,
    ]);

    const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

    // Schedule state variables
    const [isScheduleEnabled, setIsScheduleEnabled] = useState<boolean>(bioSpacingBlock.is_schedule_enabled || false);
    const [startDateTime, setStartDateTime] = useState<Date | null>(
        bioSpacingBlock.is_schedule_enabled && bioSpacingBlock.schedule_start_at ? new Date(bioSpacingBlock.schedule_start_at) : null
    );
    const [endDateTime, setEndDateTime] = useState<Date | null>(
        bioSpacingBlock.schedule_end_at ? new Date(bioSpacingBlock.schedule_end_at) : null
    );
    const [timezone, setTimezone] = useState<string>(bioSpacingBlock.schedule_timezone || 'UTC');

    // Animation state variables
    const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(bioSpacingBlock.is_animation_enabled || false);
    const [animationType, setAnimationType] = useState<string>(bioSpacingBlock.animation_type || 'tada');

    // Custom Appearance state variables
    const [isCustomAppearanceEnabled, setIsCustomAppearanceEnabled] = useState<boolean>(
        bioSpacingBlock.is_custom_appearance_enabled || false
    );
    const [customAppearance, setCustomAppearance] = useState(
        bioSpacingBlock.custom_appearance_attributes || {}
    );

    // Add block to the bio link
    const handleAddBlock = () => {
        let payload = {
            ...bioSpacingBlock,
            bio_link_id: id,
            workspace_id: defaultWorkspace?._id,
            is_schedule_enabled: isScheduleEnabled,
            schedule_start_at: startDateTime ? startDateTime.toISOString() : null,
            schedule_end_at: endDateTime ? endDateTime.toISOString() : null,
            schedule_timezone: timezone,
            // Animation fields
            is_animation_enabled: isAnimationEnabled,
            animation_type: animationType,
            // Custom Appearance fields
            is_custom_appearance_enabled: isCustomAppearanceEnabled,
            custom_appearance_attributes: customAppearance
        };

        if (bioSpacingBlock._id) {
            new BioLinkBlockService()
                .update(defaultWorkspace?._id || '', payload)
                .then((res) => {
                    if (res.data.data) {
                        notifications.show({
                            color: 'green',
                            message: res.data.message,
                        });

                        resetBioSpacingBlock();
                        reload();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if ((err.response.status === 400 || err.response.status === 422) && err?.response?.data?.message) {
                        notifications.show({
                            color: 'red',
                            message: err.response.data.message,
                        });
                    }
                });
        } else {
            payload.order = bioLink?.blocks?.length + 1 || 1;

            if (!bioLink?._id) {
                notifications.show({
                    color: 'red',
                    message: 'Please first publish your bio link.',
                });
                return;
            }
            new BioLinkBlockService()
                .create(defaultWorkspace?._id || '', payload)
                .then((res) => {
                    if (res.data.data) {
                        notifications.show({
                            color: 'green',
                            message: res.data.message,
                        });
                        resetBioSpacingBlock();
                        reload();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if ((err.response.status === 400 || err.response.status === 422) && err?.response?.data?.message) {
                        notifications.show({
                            color: 'red',
                            message: err.response.data.message,
                        });
                    }
                });
        }
    };

    // Update schedule-related props when schedule settings change
    useEffect(() => {
        setBioSpacingBlock({
            ...bioSpacingBlock,
            is_schedule_enabled: isScheduleEnabled,
            schedule_start_at: isScheduleEnabled ? (startDateTime || new Date()).toISOString() : null,
            schedule_end_at: isScheduleEnabled ? (endDateTime?.toISOString() || null) : null,
            schedule_timezone: isScheduleEnabled ? timezone : 'UTC'
        });
    }, [isScheduleEnabled, startDateTime, endDateTime, timezone]);

    // Update animation-related props when animation settings change
    useEffect(() => {
        setBioSpacingBlock({
            ...bioSpacingBlock,
            is_animation_enabled: isAnimationEnabled,
            animation_type: animationType
        });
    }, [isAnimationEnabled, animationType]);

    // Update custom appearance-related props when custom appearance settings change
    useEffect(() => {
        setBioSpacingBlock({
            ...bioSpacingBlock,
            is_custom_appearance_enabled: isCustomAppearanceEnabled,
            custom_appearance_attributes: customAppearance
        });
    }, [isCustomAppearanceEnabled, customAppearance]);

    return (
        <>
            <Text ta="center" px={'md'} pb={'md'} fz={14}>
                Add vertical spacing to create visual separation between blocks in your bio link.
            </Text>
            <Divider color="gray.2" />
            <Box p="md">
                <Fieldset mb="md">
                    <Title order={6} mb="md">Spacing Height</Title>
                    <Stack>
                        <Box>
                            <Text size="sm" fw={500} mb={8}>Height (in pixels): {bioSpacingBlock.spacing_height}px</Text>
                            <Slider
                                mb={'md'}
                                color="primary"
                                min={0}
                                step={5}
                                max={100}
                                value={bioSpacingBlock.spacing_height}
                                marks={[
                                    { value: 0, label: '0' },
                                    { value: 25, label: '25' },
                                    { value: 50, label: '50' },
                                    { value: 75, label: '75' },
                                    { value: 100, label: '100' },
                                ]}
                                label={(value) => `${value}px`}
                                onChange={(value) => {
                                    setBioSpacingBlock({
                                        ...bioSpacingBlock,
                                        spacing_height: value,
                                    });
                                }}
                            />
                        </Box>
                    </Stack>

                    <Box mt="md">
                        <Text size="sm" fw={500} mb={8}>Preview</Text>
                        <Box style={{
                            padding: '10px',
                            border: '1px solid #e9ecef',
                            borderRadius: '4px',
                            backgroundColor: '#f8f9fa'
                        }}>
                            <Box style={{
                                height: `${bioSpacingBlock.spacing_height}px`,
                                backgroundColor: '#e9ecef50',
                                border: '1px dashed #ced4da',
                                borderRadius: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Text size="xs" color="dimmed">{bioSpacingBlock.spacing_height}px height</Text>
                            </Box>
                        </Box>
                    </Box>
                </Fieldset>

                {/* Schedule Section */}
                <ScheduleSection
                    isScheduleEnabled={isScheduleEnabled}
                    setIsScheduleEnabled={setIsScheduleEnabled}
                    startDateTime={startDateTime}
                    setStartDateTime={setStartDateTime}
                    endDateTime={endDateTime}
                    setEndDateTime={setEndDateTime}
                    timezone={timezone}
                    setTimezone={setTimezone}
                />

                {/* Animation Section */}
                <AnimationSection
                    isAnimationEnabled={isAnimationEnabled}
                    setIsAnimationEnabled={setIsAnimationEnabled}
                    animationType={animationType}
                    setAnimationType={setAnimationType}
                />

                {/* Custom Appearance Section */}
                <CustomAppearanceSection
                    isCustomAppearanceEnabled={isCustomAppearanceEnabled}
                    setIsCustomAppearanceEnabled={setIsCustomAppearanceEnabled}
                    customAppearance={customAppearance}
                    setCustomAppearance={setCustomAppearance}
                />

                <Divider my={'md'} />
                <Center>
                    <Group>
                        <Button onClick={handleAddBlock}>
                            {bioSpacingBlock._id ? 'Update Block' : 'Add Block'}
                        </Button>
                    </Group>
                </Center>
            </Box>
        </>
    );
};