// src/stores/Bio/useBioSeparatorBlockStore.ts
import { DEFAULT_SEPARATOR_BLOCK_VALUES, IBioSeparatorBlock } from '@replug/utils';
import { create } from 'zustand';

interface IBioSeparatorBlockStore {
  bioSeparatorBlock: IBioSeparatorBlock;
  setBioSeparatorBlock: (bioSeparatorBlock: IBioSeparatorBlock) => void;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  reset: () => void;
}

export const useBioSeparatorBlockStore = create<IBioSeparatorBlockStore>((set, get) => ({
  bioSeparatorBlock: DEFAULT_SEPARATOR_BLOCK_VALUES,
  setBioSeparatorBlock: (bioSeparatorBlock: IBioSeparatorBlock) => {
    set({ bioSeparatorBlock });
  },
  loader: false,
  setLoader: (loader: boolean) => {
    set({ loader });
  },
  reset: () => {
    set({ bioSeparatorBlock: DEFAULT_SEPARATOR_BLOCK_VALUES });
  },
}));