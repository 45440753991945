import { BioLinkService } from '@/services/BioLinkService';
import { useBioCardBlockStore } from '@/stores/Bio/useBioCardBlockStore';
import { useBioLinkBlockStore } from '@/stores/Bio/useBioLinkBlockStore';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useBioRSSBlockStore } from '@/stores/Bio/useBioRSSBlockStore';
import { useBioSocialBlockStore } from '@/stores/Bio/useBioSocialBlockStore';
import { useBioTextBlockStore } from '@/stores/Bio/useBioTextBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { getShortenedURL } from '@/utils/LinkUtility';
import {
  faArrowLeft,
  faArrowUpRightFromSquare,
  faCheck,
  faCheckCircle,
  faClipboard,
  faClipboardCheck,
  faLink,
  faPencil,
  faPlusCircle,
  faTick,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Center,
  CopyButton,
  Divider,
  Flex,
  Grid,
  Group,
  Menu,
  Popover,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useScrollIntoView, useWindowScroll } from '@mantine/hooks';
import { BIO_SECTION_NAMES } from '@replug/utils';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import classes from './BioLinks.module.css';
import { BioBlocksListing } from './Components/BioBlocksListing';
import { BioNavMenu } from './Components/Menu/BioNavMenu';
import { BioLinkPreviewContainer } from './Components/Preview/BioLinkPreviewContainer';
import { BioBrandingSection } from './Components/Sections/BioBrandingSection';
import { BioCardSection } from './Components/Sections/BioCardSection';
import { BioLinksSection } from './Components/Sections/BioLinksSection';
import { BioRssSection } from './Components/Sections/BioRssSection';
import { BioSocialSection } from './Components/Sections/BioSocialSection';
import { BioTextSection } from './Components/Sections/BioTextSection';
import { BioVideoSection } from './Components/Sections/BioVideoSection';
import { BioAddBlocks } from './Components/BioAddBlocks';
import { BioSettingsSection } from './Components/Sections/BioSettingsSection';
import { useCustomDomainsStore } from '@/stores/useCustomDomainsStore';
import { notifications } from '@mantine/notifications';
import isEqual from 'lodash.isequal';
import { useBioVideoBlockStore } from '@/stores/Bio/useBioVideoBlockStore';
import { useCombinedUsermaven } from '@/hooks/useCombinedUsermaven';
import { BioSeparatorSection } from './Components/Sections/BioSeparatorSection';
import { useBioSeparatorBlockStore } from '@/stores/Bio/useBioSeparatorBlockStore';
import { BioSpacingSection } from './Components/Sections/BioSpacingSection';
import { useBioSpacingBlockStore } from '@/stores/Bio/useBioSpacingBlockStore';

export const BioLinksSetupPage = () => {
  const [scroll, scrollTo] = useWindowScroll();
  const [getConnectedCustomDomains] = useCustomDomainsStore((state) => [
    state.getConnectedCustomDomains,
  ]);
  const { track } = useCombinedUsermaven()
  const { id } = useParams();
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement>({
    offset: 5000,
  });
  const [isEditSlug, setIsEditSlug] = useState(false);

  const [customDomainLoader, setCustomDomainLoader] = useState(false);
  const [disableUpdate, setdisableUpdate] = useState(true);

  const [opened, { toggle }] = useDisclosure();
  const [section, setSection] = useState(BIO_SECTION_NAMES.BRANDING);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  // Bio Link Store Setup

  const [bioLink, setBioLink, loader, create, update] = useBioLinkSetupStore((state) => [
    state.bioLink,
    state.setBioLink,
    state.loader,
    state.create,
    state.update,
  ]);

  // social block
  const [socialBlock, setBioSocialBlock, resetBioSocialBlock] = useBioSocialBlockStore((state) => [
    state.bioSocialBlock,
    state.setBioSocialBlock,
    state.reset,
  ]);

  // link block
  const [bioLinkBlock, setBioLinkBlock, resetBioLinkBlock] = useBioLinkBlockStore((state) => [
    state.bioLinkBlock,
    state.setBioLinkBlock,
    state.reset,
  ]);

  // text block

  const [bioTextBlock, setBioTextBlock, resetBioTextBlock] = useBioTextBlockStore((state) => [
    state.bioTextBlock,
    state.setBioTextBlock,
    state.reset,
  ]);

  // card block

  const [bioCardBlock, setBioCardBlock, resetBioCardBlock] = useBioCardBlockStore((state) => [
    state.bioCardBlock,
    state.setBioCardBlock,
    state.reset,
  ]);

  // rss block

  const [bioRSSBlock, setBioRSSBlock, resetBioRSSBlock] = useBioRSSBlockStore((state) => [
    state.bioRSSBlock,
    state.setBioRSSBlock,
    state.reset,
  ]);

  // video block

  const [bioVideoBlock, setBioVideoBlock, resetBioVideoBlock] = useBioVideoBlockStore((state) => [
    state.bioVideoBlock,
    state.setBioVideoBlock,
    state.reset,
  ]);

  // Add this state hook with the other blocks
  const [bioSeparatorBlock, setBioSeparatorBlock, resetBioSeparatorBlock] = useBioSeparatorBlockStore((state) => [
    state.bioSeparatorBlock,
    state.setBioSeparatorBlock,
    state.reset,
  ]);

  // Initialize the spacing block state
  const [bioSpacingBlock, setBioSpacingBlock, resetBioSpacingBlock] = useBioSpacingBlockStore((state) => [
    state.bioSpacingBlock,
    state.setBioSpacingBlock,
    state.reset,
  ]);

  const form = useForm({
    initialValues: { ...bioLink, workspace_id: defaultWorkspace?._id },
    validate: {},
  });



  const fetchBioLink = async () => {
    await new BioLinkService()
      .getById(defaultWorkspace?._id || '', id || '')
      .then((res) => {
        if (res.data.status) {
          form.setValues({
            ...res.data.data,
          });
          setBioLink(res.data.data);
        }
      })
      .catch((err) => { });
  };

  const getSection = () => {
    const sections = [
      {
        label: 'Branding',
        value: 'branding',
        content: (
          <BioBrandingSection form={form} scrollIntoView={scrollIntoView} targetRef={targetRef} />
        ),
      },

      {
        label: 'Settings',
        value: 'settings',
        content: <BioSettingsSection form={form} />,
      },

      {
        label: 'Social Blocks',
        value: 'social',
        content: (
          <BioSocialSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
      {
        label: 'Links',
        value: 'links',
        content: (
          <BioLinksSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
      {
        label: 'RSS',
        value: 'rss',
        content: (
          <BioRssSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
      {
        label: 'Video',
        value: 'video',
        content: (
          <BioVideoSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
      {
        label: 'Card',
        value: 'card',
        content: (
          <BioCardSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
      {
        label: 'Text',
        value: 'text',
        content: (
          <BioTextSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
      {
        label: 'Separator',
        value: 'separator',
        content: (
          <BioSeparatorSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
      {
        label: 'Spacing',
        value: 'spacing',
        content: (
          <BioSpacingSection
            reload={async () => {
              setSection('');
              toggle();
              await fetchBioLink();
            }}
          />
        ),
      },
    ];
    return sections;
  };

  const filteredSection = getSection().find((s) => s.value === section);

  const handleSectionChange = (section: string) => {
    setSection(section);
    switch (section) {
      case BIO_SECTION_NAMES.SOCIAL:
        resetBioSocialBlock();
        break;
      case BIO_SECTION_NAMES.LINKS:
        resetBioLinkBlock();
        break;
      case BIO_SECTION_NAMES.TEXT:
        resetBioTextBlock();
        break;
      case BIO_SECTION_NAMES.CARD:
        resetBioCardBlock();
        break;
      case BIO_SECTION_NAMES.RSS:
        resetBioRSSBlock();
        break;
      case BIO_SECTION_NAMES.VIDEO:
        resetBioVideoBlock();
        break;
      case BIO_SECTION_NAMES.SEPARATOR:
        resetBioSeparatorBlock(); // Add this line
        break;
      case BIO_SECTION_NAMES.SPACING:
        resetBioSpacingBlock();
        break;
    }

    if (!opened) {
      toggle();
    }
  };

  const getPreviewBlocks = () => {
    const blocks = form.values.blocks;
    // check if social block is added then return only that one.
    if (socialBlock && section === BIO_SECTION_NAMES.SOCIAL) {
      return [socialBlock];
    }
    // check if link block is added then return only that one.

    if (bioLinkBlock && section === BIO_SECTION_NAMES.LINKS) {
      return [bioLinkBlock];
    }

    // check if text block is added then return only that one.

    if (bioTextBlock && section === BIO_SECTION_NAMES.TEXT) {
      return [bioTextBlock];
    }

    // check if card block is added then return only that one.

    if (bioCardBlock && section === BIO_SECTION_NAMES.CARD) {
      return [bioCardBlock];
    }

    // check if card block is added then return only that one.

    if (bioRSSBlock && section === BIO_SECTION_NAMES.RSS) {
      return [bioRSSBlock];
    }

    // check if card block is added then return only that one.
    if (bioVideoBlock && section === BIO_SECTION_NAMES.VIDEO) {
      return [bioVideoBlock];
    }

    //check if SEPARATOR block is added then return only that one.
    if (bioSeparatorBlock && section === BIO_SECTION_NAMES.SEPARATOR) {
      return [bioSeparatorBlock];
    }

    //check if space block is added then return only that one.
    if (bioSpacingBlock && section === BIO_SECTION_NAMES.SPACING) {
      return [bioSpacingBlock];
    }

    return blocks;
  };

  const handleOnEdit = (item: any) => {
    setSection(item.type);
    switch (item.type) {
      case BIO_SECTION_NAMES.SOCIAL:
        setBioSocialBlock(item);
        break;
      case BIO_SECTION_NAMES.LINKS:
        setBioLinkBlock(item);
        break;
      case BIO_SECTION_NAMES.TEXT:
        setBioTextBlock(item);
        break;
      case BIO_SECTION_NAMES.CARD:
        setBioCardBlock(item);
        break;
      case BIO_SECTION_NAMES.RSS:
        setBioRSSBlock(item);
        break;
      case BIO_SECTION_NAMES.VIDEO:
        setBioVideoBlock(item);
        break;
      case BIO_SECTION_NAMES.SEPARATOR:
        setBioSeparatorBlock(item);
        break;
      case BIO_SECTION_NAMES.SPACING:
        setBioSpacingBlock(item);
        break;
    }
    toggle();
  };

  const handleSaveOrUpdate = async () => {
    setdisableUpdate(true)
    if (!form.values._id) {
      const res = await create(defaultWorkspace?._id || '', { ...form.values });
      if (res.data._id) {
        form.setValues({ ...res.data });
        navigate(`/bio-links/${defaultWorkspace?._id}/setup/${res.data._id}`);
        track('bio_link_created', {})
      }
    } else {
      update(defaultWorkspace?._id || '', { ...form.values });
    }
  };

  const handleDomainChange = async (value: string | '') => {
    setCustomDomainLoader(true);
    form.setFieldValue('domain_id', value);
    await new BioLinkService()
      .updateDomain(defaultWorkspace?._id || '', form.values._id, value)
      .then((res) => {
        if (res.data.status) {
          fetchBioLink();
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
    setCustomDomainLoader(false);
  };

  const isNewChangesSaved = () => {
    if (bioLink?._id) {
      const problematicKey = '_id'; // Replace with the actual key

      const { [problematicKey]: discardedBioLinkValue, ...bioLinkCopy } = bioLink;
      const { [problematicKey]: discardedFormValue, ...formValuesCopy } = form.values;

      console.log('form.valuesCopy', formValuesCopy);
      console.log('bioLinkCopy', bioLinkCopy);
      console.log('isEqual', isEqual(bioLinkCopy, formValuesCopy));
      return isEqual(bioLinkCopy, formValuesCopy);
    }
    return false;
  };

  useEffect(() => {
    scrollTo({ y: 0 });
    // fetch bio link
    if (id) {
      fetchBioLink();
    } else {
      form.setValues({
        ...bioLink,
        workspace_id: defaultWorkspace?._id,
      });
    }
  }, []);

  useEffect(() => {
    // console.log('form is changed', form.values)
    setdisableUpdate(false)
  }, [form.values]);

  useEffect(() => {
    console.log('bio link is changed', bioLink)
    setdisableUpdate(false)
  }, [bioLink]);

  return (
    <>
      <AppShell
        header={{ height: 60 }}
        navbar={{ width: 100, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      // padding="md"
      >
        <AppShell.Header>
          <Flex align="center" h={60}>
            <Group
              h="100%"
              px="md"
              style={{
                flexGrow: 1,
              }}
            >
              <NavLink
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'var(--mantine-color-gray-7)',
                  fontSize: 13,
                  textDecoration: 'none',
                  //   ':hover': {
                  //     textDecoration: 'underline',
                  //   },
                }}
                to={`/bio-links/${defaultWorkspace?._id}`}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{
                    marginRight: 8,
                    fontSize: 13,
                  }}
                />
                Go Back
              </NavLink>

              {/* <Divider my={16} orientation="vertical" />
              <FontAwesomeIcon icon={faDesktopAlt} />
              <FontAwesomeIcon icon={faMobile} /> */}
              <Divider my={16} orientation="vertical" />
              {bioLink?._id && (
                <>
                  <TextInput
                    w={550}
                    size="sm"
                    placeholder="Your bio link URL"
                    disabled={!isEditSlug}
                    leftSectionWidth={
                      bioLink?.domain_attributes?.url
                        ? bioLink?.domain_attributes?.url.length * 8.1 + 24
                        : 12 + 24
                    }
                    leftSection={
                      <Flex align={'center'}>
                        <FontAwesomeIcon icon={faLink} />
                        <Text ml={12} fz={15}>
                          {bioLink?.domain_attributes?.url}
                        </Text>
                      </Flex>
                    }
                    value={form.values.short_url}
                    onChange={(event) => {
                      if (isEditSlug) {
                        form.setFieldValue('short_url', event.currentTarget.value);
                        form.setFieldValue('short_url_lowercase', event.currentTarget.value.toLowerCase());
                      }
                    }}
                    rightSectionWidth={isEditSlug ? 60 : 80}
                    rightSection={
                      <>
                        {isEditSlug ? (
                          <>
                            <Tooltip label="Save">
                              <ActionIcon
                                variant="subtle"
                                color="gray.6"
                                onClick={async () => {
                                  await update(defaultWorkspace?._id || '', { ...form.values });
                                  setIsEditSlug(!isEditSlug);
                                  form.setFieldValue('short_url', form.values.short_url);
                                }}
                              >
                                <>
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{
                                      color: theme.colors.green[6],
                                    }}
                                  />
                                </>
                              </ActionIcon>
                            </Tooltip>
                            <Tooltip label="Cancel">
                              <ActionIcon
                                variant="subtle"
                                color="gray.6"
                                onClick={() => {
                                  form.setFieldValue('short_url', bioLink.short_url);
                                  setIsEditSlug(!isEditSlug);
                                }}
                              >
                                <>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                      color: theme.colors.red[6],
                                    }}
                                  />
                                </>
                              </ActionIcon>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip label="Edit Slug">
                              <ActionIcon
                                variant="subtle"
                                color="gray.6"
                                onClick={() => {
                                  setIsEditSlug(!isEditSlug);
                                }}
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </ActionIcon>
                            </Tooltip>
                            <Tooltip label="Open link">
                              <ActionIcon
                                variant="subtle"
                                color="gray.6"
                                onClick={() => {
                                  window.open(getShortenedURL(bioLink), '_blank');
                                }}
                              >
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                              </ActionIcon>
                            </Tooltip>

                            <CopyButton value={getShortenedURL(bioLink)}>
                              {({ copied, copy }) => (
                                <Tooltip label="Copy link">
                                  <ActionIcon
                                    mr={'lg'}
                                    variant="subtle"
                                    color={copied ? 'teal' : 'gray.6'}
                                    onClick={copy}
                                  >
                                    {copied ? (
                                      <FontAwesomeIcon icon={faClipboardCheck} />
                                    ) : (
                                      <FontAwesomeIcon icon={faClipboard} />
                                    )}
                                  </ActionIcon>
                                </Tooltip>
                              )}
                            </CopyButton>
                          </>
                        )}
                      </>
                    }
                  />

                  <>
                    <Menu position="bottom" withArrow shadow="lg">
                      <Menu.Target>
                        <Button variant="outline">
                          {bioLink.domain_attributes?.url.includes('mylinkinb.io') ||
                            bioLink.domain_attributes?.url.includes('localhost')
                            ? 'Connect Custom Domain'
                            : 'Change Custom Domain'}
                        </Button>
                      </Menu.Target>
                      <Menu.Dropdown miw={250}>
                        <Menu.Item
                          onClick={() => handleDomainChange('')}
                          disabled={
                            bioLink.domain_attributes?.url.includes('mylinkinb.io') ||
                            bioLink.domain_attributes.url.includes('localhost')
                          }
                        >
                          Default Domain ({bioLink.domain_attributes?.netloc})
                        </Menu.Item>

                        {getConnectedCustomDomains().map((domain: any) => (
                          <Menu.Item onClick={() => handleDomainChange(domain._id)}>
                            <Flex align={'center'}>
                              <Flex
                                style={{
                                  flexGrow: 1,
                                }}
                              >
                                {domain.url.replace('https://', '').replace('http://', '')}
                              </Flex>

                              {bioLink.domain_id === domain._id && (
                                <>
                                  <Flex justify={'end'}>
                                    <FontAwesomeIcon
                                      style={{
                                        color: theme.colors.teal[6],
                                      }}
                                      icon={faCheckCircle}
                                    />
                                  </Flex>
                                </>
                              )}
                            </Flex>
                          </Menu.Item>
                        ))}
                      </Menu.Dropdown>
                    </Menu>
                  </>
                </>
              )}
            </Group>
            <Divider my={16} orientation="vertical" />
            <Group mr={16}>
              {(!form.values._id && !bioLink?._id) && (
                <Text fz={13}>Your changes are not saved.</Text>
              )}

              {/* {bioLink?._id && !isNewChangesSaved() && (
                <Text fz={13}>Your changes are not saved.</Text>
              )} */}
              {/* <Button variant="subtle">Share</Button> */}
              <Button
                disabled={disableUpdate}
                // loading={loader}
                onClick={handleSaveOrUpdate}
              >
                {bioLink?._id ? 'Update' : 'Publish'}
              </Button>
            </Group>
          </Flex>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <BioNavMenu filteredSection={filteredSection} handleSectionChange={handleSectionChange} />
        </AppShell.Navbar>
        <AppShell.Main>
          <Grid m={'md'}>
            <Grid.Col span={7} ref={scrollableRef}>
              {filteredSection ? (
                <>
                  <Box
                    style={{
                      backgroundColor: '#fff',
                      border: '1px solid var(--mantine-color-gray-1)',
                      boxShadow: '0 0 4px 4px rgba(0, 0, 0, 0.01)',
                    }}
                  >
                    <Flex py={12}>
                      <Title order={4} ta="center" style={{ flex: 1 }}>
                        {filteredSection.label}
                      </Title>
                      {filteredSection.value !== BIO_SECTION_NAMES.BRANDING &&
                        filteredSection.value !== BIO_SECTION_NAMES.SETTINGS && (
                          <>
                            <ActionIcon
                              mr={12}
                              color="gray"
                              variant="outline"
                              onClick={() => {
                                setSection('');
                                toggle();
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </ActionIcon>
                          </>
                        )}
                    </Flex>
                    <Divider mb={16} color="gray.2" />
                    {filteredSection.content}
                  </Box>
                </>
              ) : (
                <Box className={classes.mainContent}>
                  {form.values.blocks?.length === 0 ? (
                    <>
                      <Flex justify={'center'} align={'center'} py="lg">
                        <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                        <Text ta="center" ml={8}>
                          You do not have any blocks added yet. Click on the left to add a section.
                        </Text>
                      </Flex>
                      <Divider my={'md'} />
                      <BioAddBlocks handleSectionChange={handleSectionChange} />
                    </>
                  ) : (
                    <>
                      <BioBlocksListing form={form} onEdit={handleOnEdit} />
                      <Divider my={'md'} />
                      <BioAddBlocks handleSectionChange={handleSectionChange} />
                    </>
                  )}
                </Box>
              )}
            </Grid.Col>
            <Grid.Col span={5}>
              <Center>
                <Box
                  style={{
                    top: 80,
                    position: 'fixed',
                  }}
                >
                  <Flex direction={'column'} align={'center'} justify={'center'}>
                    <Title order={5} ta="center" mb={12}>
                      {section === '' ? 'Preview' : 'Block Preview'}
                    </Title>
                    <BioLinkPreviewContainer
                      values={form.values}
                      blocks={
                        // concat form.values.blocks and social block
                        getPreviewBlocks()
                      }
                    />
                  </Flex>
                </Box>
              </Center>
            </Grid.Col>
          </Grid>
        </AppShell.Main>
      </AppShell>
    </>
  );
};
