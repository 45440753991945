// File: src/stores/Bio/useBioSpacingBlockStore.ts

import { DEFAULT_SPACING_BLOCK_VALUES, IBioSpacingBlock } from '@replug/utils';
import { create } from 'zustand';

interface IBioSpacingBlockStore {
    bioSpacingBlock: IBioSpacingBlock;
    setBioSpacingBlock: (bioSpacingBlock: IBioSpacingBlock) => void;
    loader: boolean;
    setLoader: (loader: boolean) => void;
    reset: () => void;
}

export const useBioSpacingBlockStore = create<IBioSpacingBlockStore>((set, get) => ({
    bioSpacingBlock: DEFAULT_SPACING_BLOCK_VALUES,
    setBioSpacingBlock: (bioSpacingBlock: IBioSpacingBlock) => {
        set({ bioSpacingBlock });
    },
    loader: false,
    setLoader: (loader: boolean) => {
        set({ loader });
    },
    reset: () => {
        set({ bioSpacingBlock: DEFAULT_SPACING_BLOCK_VALUES });
    },
}));