import axios from './JWTService';

export class UserService {
  getProfile = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('/me')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updateProfileInformation = (form: { first_name: string; last_name: string; profile_image?: string; last_login_at?: string }): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/user/update_profile', form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  changeEmail = (form: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/settings/changeEmail', form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
  cancelChangeEmailRequest = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/settings/cancelChangeEmail')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  resendChangeEmail = (email: any, workspace_id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/settings/resendChangeEmail', { 'email': email, 'workspace_id': workspace_id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  updatePassword = (form: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/user/update_password', form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  verifyEmail = (token: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/user/verify_email/${token}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  resendVerificationEmail = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/user/resend_verification_email')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  verifyNewEmail = (token: any, newEmail: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`/settings/verifyNewEmail/${token}/${newEmail}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  createPromoter = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/first-promoter/create')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  setUserOnBoardingTrue = (id: any): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/user/check_new_user', { user_id: id })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  registerTeamMember = (form: {
    _id: string | null;
    name: string;
    url: string;
    logo?: string | null;
  }): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('/user/register-team-member', form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  checkUserOrganizationStatus = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('/user/check-user-status')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}
