import cx from 'clsx';
import { ActionIcon, Anchor, Badge, Flex, Group, rem, Text, Title, Tooltip } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { IconGripVertical } from '@tabler/icons-react';
import classes from './BlocksListing.module.css';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { BIO_SECTION_NAMES, findSocialNetworkByValue } from '@replug/utils';
import { modals } from '@mantine/modals';
import { BioLinkService } from '@/services/BioLinkService';
import { notifications } from '@mantine/notifications';
import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import parse from 'html-react-parser';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export const BioBlocksListing = ({
  form,
  onEdit,
}: {
  form: any;
  onEdit: (value: string) => void;
}) => {
  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);
  const [state, handlers] = useListState(form.values.blocks || []);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const confirmDeleteBlock = (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Block</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteBlock(id),
    });
  };

  const handleDeleteBlock = async (id: string) => {
    await new BioLinkBlockService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          // Remove the item from the list
          const newList = state.filter((item: any) => item._id !== id);
          form.setFieldValue('blocks', newList);
          handlers.setState(newList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTitle = (item: any) => {
    switch (item.type) {
      case BIO_SECTION_NAMES.LINKS:
        if (item.is_show_title_enabled) {
          return item.title;
        } else {
          return `${item.links.length} link(s)`;
        }
      case BIO_SECTION_NAMES.TEXT:
        return parse(item.text);
      case BIO_SECTION_NAMES.SEPARATOR:
        return `Separator (${item.separator_style})`;
      case BIO_SECTION_NAMES.SPACING:
        return `Spacing (${item.spacing_height}px)`;
      default:
        return item.title;
    }
  };

  const handleUpdatesBlockOrder = async (blocks: any) => {
    await new BioLinkBlockService()
      .orderUpdate(defaultWorkspace?._id || '', { blocks, bio_link_id: bioLink?._id })
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderSpacingPreview = (item: any) => {
    if (item.type !== BIO_SECTION_NAMES.SPACING) return null;
    
    return (
      <div 
        style={{ 
          width: '100%', 
          height: `${Math.min(item.spacing_height, 30)}px`, // Limit preview height
          backgroundColor: '#f5f5f5',
          marginTop: '8px',
          border: '1px dashed #ddd',
        }}
      ></div>
    );
  };

  // Render a preview of the separator if it's a separator block
  const renderSeparatorPreview = (item: any) => {
    if (item.type !== BIO_SECTION_NAMES.SEPARATOR) return null;
    
    return (
      <div 
        style={{ 
          width: '100%', 
          marginTop: '8px',
          borderTopWidth: '1px',
          borderTopStyle: item.separator_style, 
          borderTopColor: item.separator_color,
        }}
      ></div>
    );
  };

  const items = state.map((item: any, index) => (
    <Draggable key={item._id} index={index} draggableId={item._id}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </div>
          <Flex direction={'column'} w="100%">
            <Badge variant="outline" color="primary">
              {item.type} Block
            </Badge>

            <Text my={'sm'} fz={14} fw={500} style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}>
              {getTitle(item)}
            </Text>
            {item.type === BIO_SECTION_NAMES.SOCIAL && (
              <Flex>
                {item.channels_selected?.map((channel: any, index: number) => (
                  <Anchor href={item.channels_urls[index]} target="_blank" key={channel}>
                    <ActionIcon mr={4} variant="subtle" color="gray">
                      {findSocialNetworkByValue(channel)?.icon}
                    </ActionIcon>
                  </Anchor>
                ))}
              </Flex>
            )}
            {renderSeparatorPreview(item)}
            {renderSpacingPreview(item)}
          </Flex>

          <div>
            <Flex>
              <Tooltip label="Edit">
                <ActionIcon variant="outline" color="gray" mr="xs" onClick={() => onEdit(item)}>
                  <FontAwesomeIcon icon={faEdit} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Remove">
                <ActionIcon
                  variant="outline"
                  color="red"
                  onClick={() => confirmDeleteBlock(item._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </div>
        </div>
      )}
    </Draggable>
  ));

  useEffect(() => {
    handlers.setState(form.values.blocks);
  }, [form.values.blocks]);

  return (
    <>
      <Title order={4} ta="center" my={'md'}>
        Your Blocks
      </Title>
      <Text fz={14} ta="center" mb={'md'}>
        You can reorder your blocks by dragging and dropping them.
      </Text>
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          // Check if the item's position has changed
          if (!destination || destination.index === source.index) {
            // Item was not moved to a new position
            console.log('Item was not moved to a new position');
            return;
          }

          // Proceed to reorder and log a message to the console
          handlers.reorder({ from: source.index, to: destination?.index || 0 });

          // update the form values blocks without using state variable as it has old value.
          // find the index and then update it
          const newBlocks = form.values.blocks;
          const [removed] = newBlocks.splice(source.index, 1);
          newBlocks.splice(destination.index, 0, removed);

          // modify the order for the newBlocks which has order field according to index + 1

          newBlocks.forEach((block: any, index: number) => {
            block.order = index + 1;
          });

          handleUpdatesBlockOrder(newBlocks);
          form.setFieldValue('blocks', newBlocks);

          console.log(`Item moved from position ${source.index} to ${destination.index}`);
        }}
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};