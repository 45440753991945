// File: src/pages/BioLinks/Components/Themes/BioAppearance.tsx

import {
  Fieldset,
  Flex,
  Text,
  Title,
  Group,
  Button,
  Divider,
  ColorInput,
  Box,
  Switch,
  Loader,
} from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { useRef, useState } from 'react';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UtilsService } from '@/services/UtilsService';
import { notifications } from '@mantine/notifications';

const BOX_SHADOW_VALUE = '0px 4px 6px rgba(0, 0, 0, 0.3)';
export const BioAppearance = ({ form }: { form: any }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);

  const isVariantActive = (variant: string, radius: string, boxShadow: string = '') => {
    if (form.values.theme === undefined) {
      return false;
    }
    if (
      form.values.theme.button?.variant === variant &&
      form.values.theme.button.radius === radius &&
      form.values.theme.button.box_shadow === boxShadow
    ) {
      return true;
    }
    return false;
  };

  const handleChange = (variant: string, radius: string, boxShadow: string) => {
    form.setFieldValue('theme.button.variant', variant);
    form.setFieldValue('theme.button.radius', radius);
    form.setFieldValue('theme.button.box_shadow', boxShadow);
  };

  const openFileInput = () => {
    // Trigger the click event on the file input element
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadBackgroundImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      // Validate file size (5MB limit)
      if (files[0].size > 5 * 1024 * 1024) {
        notifications.show({
          message: 'Image size should be less than 5MB',
          color: 'red',
        });
        return;
      }

      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          if (res.data.status) {
            form.setFieldValue('theme.background_image', res.data.url);
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 422) {
            notifications.show({
              message: err.response.data.message,
              color: 'red',
            });
          }
        });

      setLoadingImage(false);
    }
  };

  const handleToggleBackgroundImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('theme.is_background_image_enabled', e.currentTarget.checked);
  };

  return (
    <>
      {/* {JSON.stringify(form.values.theme)} */}

      <Fieldset>
        <Flex>
          <Title ta="left" order={4} mb={'md'}>
            Select Button Style
          </Title>
        </Flex>
        <Group>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('filled', 'sm', '');
            }}
            className={isVariantActive('filled', 'sm', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="filled" color="black"></Button>
          </Box>
          <Box
            onClick={() => {
              handleChange('filled', 'md', '');
            }}
            p={'sm'}
            className={isVariantActive('filled', 'md', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="filled" radius={'md'} color="black"></Button>
          </Box>
          <Box
            onClick={() => {
              handleChange('filled', 'xl', '');
            }}
            p={'sm'}
            className={isVariantActive('filled', 'xl', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="filled" radius={'xl'} color="black"></Button>
          </Box>

          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'sm', '');
            }}
            className={isVariantActive('outline', 'sm', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="outline" color="black"></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'md', '');
            }}
            className={isVariantActive('outline', 'md', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="outline" radius={'md'} color="black"></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'xl', '');
            }}
            className={isVariantActive('outline', 'xl', '') ? classes.buttonVariantActive : ''}
          >
            <Button w={200} variant="outline" radius={'xl'} color="black"></Button>
          </Box>

          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'sm', BOX_SHADOW_VALUE);
            }}
            className={
              isVariantActive('outline', 'sm', BOX_SHADOW_VALUE) ? classes.buttonVariantActive : ''
            }
          >
            <Button
              style={{
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
              }}
              w={200}
              variant="outline"
              color="gray.4"
            ></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'md', BOX_SHADOW_VALUE);
            }}
            className={
              isVariantActive('outline', 'md', BOX_SHADOW_VALUE) ? classes.buttonVariantActive : ''
            }
          >
            <Button
              style={{
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
              }}
              w={200}
              variant="outline"
              radius={'md'}
              color="gray.4"
            ></Button>
          </Box>
          <Box
            p={'sm'}
            onClick={() => {
              handleChange('outline', 'xl', BOX_SHADOW_VALUE);
            }}
            className={
              isVariantActive('outline', 'xl', BOX_SHADOW_VALUE) ? classes.buttonVariantActive : ''
            }
          >
            <Button
              style={{
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
              }}
              w={200}
              variant="outline"
              radius={'xl'}
              color="gray.4"
            ></Button>
          </Box>
        </Group>
      </Fieldset>

      <Divider my={'md'} />
      <Fieldset>
        <Title ta="left" order={4} mb={'md'}>
          Customize Colors
        </Title>
        <Group>
          <ColorInput
            w={200}
            label="Background"
            mr={8}
            {...form.getInputProps('theme.background_color')}
          />
          <ColorInput
            w={200}
            label="Button background"
            mr={8}
            {...form.getInputProps('theme.button.background_color')}
          />
          <ColorInput
            w={200}
            label="Button text"
            mr={8}
            {...form.getInputProps('theme.button.color')}
          />
          <ColorInput w={200} label="Text Color" mr={8} {...form.getInputProps('theme.color')} />
          {/* <ColorInput w={200} label="Page title" mr={8} />
          <ColorInput w={200} label="Page subtitle" /> */}
        </Group>
      </Fieldset>

      <Divider my={'md'} />
      <Fieldset>
        <Flex justify="space-between" align="center">
          <Title ta="left" order={4} mb={'md'}>
            Background Image
          </Title>
          <Switch
            checked={form.values.theme?.is_background_image_enabled || false}
            onChange={handleToggleBackgroundImage}
            label="Enable background image"
          />
        </Flex>
        
        {form.values.theme?.is_background_image_enabled && (
          <>
            <input
              type="file"
              accept="image/jpeg,image/png,image/webp"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={uploadBackgroundImage}
            />
            
            <Flex direction="column" align="center" my="md">
              <Box 
                style={{
                  width: '100%',
                  height: '150px',
                  border: '2px dashed #ccc',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundImage: form.values.theme?.background_image ? `url(${form.values.theme.background_image})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={openFileInput}
              >
                {!form.values.theme?.background_image && !loadingImage && (
                  <>
                    <FontAwesomeIcon icon={faImage} size="2x" color="#ccc" />
                    <Text mt="xs" color="dimmed">
                      Drag image here or click to select file
                    </Text>
                    <Text size="xs" color="dimmed" mt={5}>
                      Recommended size: Less than 5MB (1920 x 1080)
                    </Text>
                  </>
                )}
                
                {loadingImage && (
                  <Loader size="md" />
                )}
                
                {form.values.theme?.background_image && !loadingImage && (
                  <Box 
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '10px',
                      background: 'rgba(0,0,0,0.5)',
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Text size="xs">Change image</Text>
                  </Box>
                )}
              </Box>
              
              {form.values.theme?.background_image && (
                <Flex mt="md" justify="space-between" w="100%">
                  <Button
                    color="red"
                    variant="subtle"
                    onClick={() => form.setFieldValue('theme.background_image', null)}
                  >
                    Remove Image
                  </Button>
                  
                  <Group>
                    <Text size="sm">Background Opacity:</Text>
                    <input
                      type="range"
                      min="0.1"
                      max="1"
                      step="0.1"
                      value={form.values.theme?.background_opacity || 1}
                      onChange={(e) => form.setFieldValue('theme.background_opacity', parseFloat(e.target.value))}
                    />
                    <Text size="sm">{((form.values.theme?.background_opacity || 1) * 100).toFixed(0)}%</Text>
                  </Group>
                </Flex>
              )}
            </Flex>
          </>
        )}
      </Fieldset>
    </>
  );
};