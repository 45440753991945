import React from 'react';
import { Box, ScrollArea, Text } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { BioCard, BioLink, BioProfile, BioRSS, BioSeparator, BioSocial, BioText, BioVideo, BioSpacing } from '@replug/ui';
import { createContrastGradientBackground } from '@replug/utils';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export const BioLinkPreviewContainer = ({
  values,
  blocks,
  isListing = false,
}: {
  values: any;
  blocks: any;
  isListing?: boolean;
}) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const getBlockComponent = (block: any, index: any) => {
    switch (block.type) {
      case 'text':
        return <BioText values={block} theme={values.theme} />;
      case 'social':
        return <BioSocial values={block} theme={values.theme} />;
      case 'rss':
        return <BioRSS values={block} theme={values.theme} />;
      case 'video':
        return <BioVideo values={block} theme={values.theme} />;
      case 'card':
        return <BioCard values={block} theme={values.theme} />;
      case 'links':
        return <BioLink values={block} theme={values.theme} />;
      case 'separator':
        return <BioSeparator values={block} theme={values.theme} />;
      case 'spacing':
        return <BioSpacing values={block} theme={values.theme} />;
      default:
        return <Text>...</Text>;
    }
  };

  // Helper function to create background styles
  const getBackgroundStyles = () => {
    if (values.theme?.is_background_image_enabled && values.theme?.background_image) {
      return {
        backgroundImage: `linear-gradient(rgba(${hexToRgb(values.theme?.background_color || '#fff')}, ${values.theme?.background_opacity || 1}), rgba(${hexToRgb(values.theme?.background_color || '#fff')}, ${values.theme?.background_opacity || 1})), url(${values.theme.background_image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    }

    return {
      background: values.theme?.background_style === 'gradient'
        ? createContrastGradientBackground(values.theme?.background_color)
        : values.theme?.background_color || '#fff',
    };
  };

  // Helper function to convert hex to rgb
  const hexToRgb = (hex: string) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ?
      `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` :
      '255, 255, 255';
  };

  const containerHeight = isListing ? '620px' : '680px';
  const footerHeight = defaultWorkspace?.powered_by ? '40px' : '0px';
  const notchHeight = '0px';
  const homeIndicatorHeight = '0px';
  const scrollableHeight = `calc(${containerHeight} - ${notchHeight} - ${homeIndicatorHeight} - ${footerHeight})`;

  return (
    <Box
      style={{
        width: '320px',
        maxWidth: '320px',
        height: containerHeight,
        border: '3px solid #222',
        borderRadius: '32px',
        overflow: 'hidden',
        backgroundColor: '#222',
        position: 'relative',
      }}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '28px',
          overflow: 'hidden',
          position: 'relative',
          ...getBackgroundStyles()
        }}
      >
        {/* iPhone Notch */}
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '40%',
            height: notchHeight,
            backgroundColor: '#000',
            borderBottomLeftRadius: '14px',
            borderBottomRightRadius: '14px',
            zIndex: 10,
          }}
        />

        {/* Scrollable Content Area */}
        <ScrollArea
          h={scrollableHeight}
          mt={notchHeight}
          style={{
            padding: '0 12px',
          }}
          scrollbarSize={2}
          type="always"
          offsetScrollbars
        >
          <Box style={{ padding: '0px 0', display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <BioProfile values={values} />

            {blocks.map((block: any, index: any) => (
              <Box key={index}>
                {getBlockComponent(block, index)}
              </Box>
            ))}
          </Box>
        </ScrollArea>

        {/* Footer (if applicable) */}
        {defaultWorkspace?.powered_by && !values?.white_label && !defaultWorkspace?.agency?.is_whitelabel_enabled && (
          <Box
            style={{
              position: 'absolute',
              bottom: homeIndicatorHeight,
              left: 0,
              right: 0,
              height: footerHeight,
              padding: '10px 0',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(5px)',
              textAlign: 'center',
              borderTop: '1px solid rgba(0, 0, 0, 0.05)',
              zIndex: 5,
            }}
          >
            <Text fz={11} color={'gray'}>
              Bio link created using <strong>{defaultWorkspace?.agency?.name}</strong>
            </Text>
          </Box>
        )}

        {/* iPhone Home Indicator */}
        <Box
          style={{
            position: 'absolute',
            bottom: '8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '30%',
            height: '0px',
            backgroundColor: '#000',
            borderRadius: '2px',
            zIndex: 10,
          }}
        />
      </Box>
    </Box>
  );
};