import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useBioSeparatorBlockStore } from '@/stores/Bio/useBioSeparatorBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  Box,
  Button,
  Center,
  ColorInput,
  Divider,
  Fieldset,
  Group,
  Slider,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { DEFAULT_SEPARATOR_BLOCK_VALUES } from '@replug/utils';
import { useParams } from 'react-router-dom';
import { ScheduleSection } from '../ScheduleSection';
import { useEffect, useState } from 'react';
import { AnimationSection } from '../AnimationSection';
import { CustomAppearanceSection } from '../CustomAppearanceSection';
import { SegmentedControl } from '@mantine/core';

export const BioSeparatorSection = ({ reload }: { reload: () => void }) => {
  const { id } = useParams();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const [bioSeparatorBlock, setBioSeparatorBlock, resetBioSeparatorBlock] = useBioSeparatorBlockStore((state) => [
    state.bioSeparatorBlock,
    state.setBioSeparatorBlock,
    state.reset,
  ]);

  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

  // Schedule state variables
  const [isScheduleEnabled, setIsScheduleEnabled] = useState<boolean>(bioSeparatorBlock.is_schedule_enabled || false);
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    bioSeparatorBlock.is_schedule_enabled && bioSeparatorBlock.schedule_start_at ? new Date(bioSeparatorBlock.schedule_start_at) : null
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    bioSeparatorBlock.schedule_end_at ? new Date(bioSeparatorBlock.schedule_end_at) : null
  );
  const [timezone, setTimezone] = useState<string>(bioSeparatorBlock.schedule_timezone || 'UTC');
  
  // Animation state variables
  const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(bioSeparatorBlock.is_animation_enabled || false);
  const [animationType, setAnimationType] = useState<string>(bioSeparatorBlock.animation_type || 'tada');
  
  // Custom Appearance state variables
  const [isCustomAppearanceEnabled, setIsCustomAppearanceEnabled] = useState<boolean>(
    bioSeparatorBlock.is_custom_appearance_enabled || false
  );
  const [customAppearance, setCustomAppearance] = useState(
    bioSeparatorBlock.custom_appearance_attributes || DEFAULT_SEPARATOR_BLOCK_VALUES.custom_appearance_attributes
  );

  // Add block to the bio link
  const handleAddBlock = () => {
    let payload = {
      ...bioSeparatorBlock,
      bio_link_id: id,
      workspace_id: defaultWorkspace?._id,
      is_schedule_enabled: isScheduleEnabled,
      schedule_start_at: startDateTime ? startDateTime.toISOString() : null,
      schedule_end_at: endDateTime ? endDateTime.toISOString() : null,
      schedule_timezone: timezone,
      // Animation fields
      is_animation_enabled: isAnimationEnabled,
      animation_type: animationType,
      // Custom Appearance fields
      is_custom_appearance_enabled: isCustomAppearanceEnabled,
      custom_appearance_attributes: customAppearance
    };

    if (bioSeparatorBlock._id) {
      new BioLinkBlockService()
        .update(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            resetBioSeparatorBlock();
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
          if ((err.response.status === 400 || err.response.status === 422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      payload.order = bioLink?.blocks?.length + 1 || 1;

      if (!bioLink?._id) {
        notifications.show({
          color: 'red',
          message: 'Please first publish your bio link.',
        });
        return;
      }
      new BioLinkBlockService()
        .create(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });
            resetBioSeparatorBlock();
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
          if ((err.response.status === 400 || err.response.status === 422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }
  };

  // Update schedule-related props when schedule settings change
  useEffect(() => {
    setBioSeparatorBlock({
      ...bioSeparatorBlock,
      is_schedule_enabled: isScheduleEnabled,
      schedule_start_at: isScheduleEnabled ? (startDateTime || new Date()).toISOString() : null,
      schedule_end_at: isScheduleEnabled ? (endDateTime?.toISOString() || null) : null,
      schedule_timezone: isScheduleEnabled ? timezone : 'UTC'
    });
  }, [isScheduleEnabled, startDateTime, endDateTime, timezone]);

  // Update animation-related props when animation settings change
  useEffect(() => {
    setBioSeparatorBlock({
      ...bioSeparatorBlock,
      is_animation_enabled: isAnimationEnabled,
      animation_type: animationType
    });
  }, [isAnimationEnabled, animationType]);

  // Update custom appearance-related props when custom appearance settings change
  useEffect(() => {
    setBioSeparatorBlock({
      ...bioSeparatorBlock,
      is_custom_appearance_enabled: isCustomAppearanceEnabled,
      custom_appearance_attributes: customAppearance
    });
  }, [isCustomAppearanceEnabled, customAppearance]);

  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Add a separator line to your bio link. Use this to visually separate content sections.
      </Text>
      <Divider color="gray.2" />
      <Box p="md">
        <Fieldset mb="md">
          <Title order={6} mb="md">Separator Style</Title>
          <SegmentedControl
            fullWidth
            value={bioSeparatorBlock.separator_style}
            onChange={(value) => {
              setBioSeparatorBlock({
                ...bioSeparatorBlock,
                separator_style: value,
              });
            }}
            data={[
              { label: 'Solid', value: 'solid' },
              { label: 'Dashed', value: 'dashed' },
              { label: 'Dotted', value: 'dotted' },
            ]}
          />

          <Stack mt="lg">
            <ColorInput
              label="Separator Color"
              format="rgba"
              withEyeDropper
              swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#E9ECEF', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
              value={bioSeparatorBlock.separator_color}
              onChange={(value) => {
                setBioSeparatorBlock({
                  ...bioSeparatorBlock,
                  separator_color: value,
                });
              }}
            />
            
            <Box>
              <Text size="sm" fw={500} mb={8}>Margin</Text>
              <Slider
                mb={'md'}
                color="primary"
                min={0}
                step={1}
                max={100}
                value={bioSeparatorBlock.separator_margin}
                marks={[
                  { value: 0, label: '0' },
                  { value: 20, label: '20' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' },
                ]}
                label={(value) => `${value}px`}
                onChange={(value) => {
                  setBioSeparatorBlock({
                    ...bioSeparatorBlock,
                    separator_margin: value,
                  });
                }}
              />
            </Box>
          </Stack>

          <Box mt="md">
            <Text size="sm" fw={500} mb={8}>Preview</Text>
            <Box style={{ padding: '20px', border: '1px solid #e9ecef', borderRadius: '4px' }}>
              <div style={{ 
                width: '100%',
                borderTopWidth: '1px',
                borderTopStyle: bioSeparatorBlock.separator_style as any, 
                borderTopColor: bioSeparatorBlock.separator_color,
                marginTop: bioSeparatorBlock.separator_margin + 'px',
                marginBottom: bioSeparatorBlock.separator_margin + 'px',
              }}></div>
            </Box>
          </Box>
        </Fieldset>

        {/* Schedule Section */}
        <ScheduleSection
          isScheduleEnabled={isScheduleEnabled}
          setIsScheduleEnabled={setIsScheduleEnabled}
          startDateTime={startDateTime}
          setStartDateTime={setStartDateTime}
          endDateTime={endDateTime}
          setEndDateTime={setEndDateTime}
          timezone={timezone}
          setTimezone={setTimezone}
        />

        {/* Animation Section */}
        <AnimationSection
          isAnimationEnabled={isAnimationEnabled}
          setIsAnimationEnabled={setIsAnimationEnabled}
          animationType={animationType}
          setAnimationType={setAnimationType}
        />

        {/* Custom Appearance Section */}
        <CustomAppearanceSection
          isCustomAppearanceEnabled={isCustomAppearanceEnabled}
          setIsCustomAppearanceEnabled={setIsCustomAppearanceEnabled}
          customAppearance={customAppearance}
          setCustomAppearance={setCustomAppearance}
        />

        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioSeparatorBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};