// components/Modals/OnboardingModal/OnboardingModal.tsx
import {
    Alert,
    Avatar,
    Box,
    Button,
    Flex,
    Grid,
    Group,
    Image,
    Modal,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useEffect, useState } from 'react';
import { WorkspaceLogoUpload } from '@/components/ImageUploaders/WorkspaceLogoUpload';
import { notifications } from '@mantine/notifications';
import { z } from 'zod';
import { UserService } from '@/services/UserService';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@/stores/useUserStore';
import { ensureHttps } from '@/utils/LinkUtility';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWorkspace } from '@/types/workspace';
import { useCombinedUsermaven } from '@/hooks/useCombinedUsermaven';
import { isWorkspaceLocked } from '@/utils/CommonUtility';
import ReplugLogo from '@/assets/images/logos/replug-logo-black.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

const schema = z.object({
    _id: z.string().nullable(),
    name: z.string().min(2, { message: 'Name should have at least 2 letters' }).max(100, { message: 'Name cannot exceed 100 characters' }),
    url: z.string().url({ message: 'Invalid URL' }).min(2, { message: 'URL is required.' }),
    logo: z.nullable(z.string()),
});

interface OnboardingModalProps {
    opened: boolean;
    onClose: () => void;
    isForced?: boolean; // New prop to control modal closing behavior
    showAlert?: boolean;
}

interface WorkspaceMember {
    workspace: IWorkspace;
    is_default: boolean;
    role: string;
}

export function OnboardingModal({ opened, onClose, isForced = false, showAlert = false }: OnboardingModalProps) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [setUser] = useUserStore((state) => [state.setUser]);
    const userService = new UserService();
    const { track } = useCombinedUsermaven();
    const [workspaces, setWorkspaces, setDefaultWorkspace, setManageWorkspacesToggle] = useWorkspaceStore((state) => [
        state.workspaces,
        state.setWorkspaces,
        state.setDefaultWorkspace,
        state.setManageWorkspaceToggle
    ]);

    const form = useForm({
        initialValues: {
            _id: null,
            name: '',
            url: '',
            logo: null,
            type: null,
        },
        validate: zodResolver(schema),
    });

    const handleURLChange = (event: any) => {
        let value = event.currentTarget.value;
        if (value) {
            form.setFieldValue('url', ensureHttps(value));
        } else {
            form.setFieldValue('url', value);
        }
    };

    const handleSubmit = async (formValues: any) => {
        setLoading(true);
        await userService.registerTeamMember(formValues)
            .then((res) => {
                if (res.data.status) {
                    onClose();
                    // Changed to match backend response which returns member with workspace data
                    const member = res.data.data;
                    const workspaceItem: IWorkspace = {
                        ...member.workspace,
                        is_default: member.is_default,
                        role: member.role,
                    };
                    setWorkspaces([...workspaces, workspaceItem]);
                    setUser(res.data.user); // Update user if returned from backend
                    notifications.show({
                        color: 'green',
                        message: res.data.message,
                    });
                    track('workspace_created', {
                        workspace_name: workspaceItem.name,
                        workspace_id: workspaceItem._id
                    });
                    handleWorkspaceChange(workspaceItem);
                } else {
                    notifications.show({
                        color: 'red',
                        message: res.data.message,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 400 || err.response.status === 422) {
                    notifications.show({
                        color: 'red',
                        message: err.response.data.message,
                    });
                } else {
                    notifications.show({
                        color: 'red',
                        message: 'Failed to create workspace',
                    });
                }
            });
        setLoading(false);
    };

    const handleWorkspaceChange = (workspace: any) => {
        if (isWorkspaceLocked(workspace)) return;
        setDefaultWorkspace(workspace);
        setManageWorkspacesToggle(false);
        navigate(`/dashboard/${workspace._id}`);
    };

    useEffect(() => {
        if (!opened) {
            form.reset();
        }
    }, [opened]);


    return (
        <Modal
            opened={opened}
            onClose={onClose}
            size={1000}
            closeOnClickOutside={!isForced}
            closeOnEscape={!isForced}
            withCloseButton={!isForced}
            styles={{
                header: {
                    display: 'none'
                },
                body: {
                    padding: '0',
                },
                root: {
                    backgroundColor: 'white',
                },
                inner: {
                    padding: '20px',
                },
                content: {
                    height: 'auto',
                    maxHeight: 'none',
                    borderRadius: '10px',
                },
            }}
        >
            <Grid mt={0} p={0} gutter={0}>
                {/* Left Column */}
                <Grid.Col span={5} p={24} style={{
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                    backgroundColor: '#f5f6f8'
                }}>
                    <div style={{
                        gap: '12px',
                        paddingTop: '20px',
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'left',
                        }}>
                            <Image
                                src={ReplugLogo}
                                alt="Replug"
                                width={36}
                                height={36}
                                style={{
                                    objectFit: 'contain',
                                    textAlign: 'left',
                                    paddingLeft: '24px'
                                }}
                            />
                        </div>
                        <div style={{ paddingTop: '100px', paddingLeft: '24px' }}>
                            <Text size="xl" fw={600} mb={4} style={{ color: '#464e52' }}>
                                Welcome <span style={{ fontWeight: 600, color: '#2b70fb' }}>Onboard!</span> 👋
                            </Text>
                            <Text pt={30} fw={600} style={{ fontSize: '24px', color: '#464e52' }}>
                                Kick off your 14-day trial today!
                            </Text>
                            <Text size="sm" fw={300} pt={30} style={{ fontweight: '300', color: '#464e52' }}>
                                This will help us create a more personalized experience for you.
                            </Text>
                            {/* Only show alert if modal is forced */}
                            {showAlert && (
                                <Alert
                                    title={
                                        <Flex justify={'center'} align={'center'} columnGap={'5px'}>
                                            Important <FontAwesomeIcon icon={faCircleExclamation} />
                                        </Flex>
                                    }
                                    color="yellow"
                                    style={{ marginTop: '20px' }}
                                >
                                    <Text size="sm" fw={300}>
                                        You've been removed from your previous workspace. Create your own workspace to continue using Replug.
                                    </Text>
                                </Alert>
                            )}
                        </div>
                    </div>
                </Grid.Col>

                {/* Right Column */}
                <Grid.Col span={7} p={50} >
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <div style={{ padding: '20px', backgroundColor: '#f5f6f8', borderRadius: '0px', marginTop: '20px', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.07)' }}>
                            {/* <Text size="xl" fw={600} mb={4} style={{ color: '#464e52' }} >
                                Workspace Details
                            </Text> */}
                            <div style={{ marginBottom: '24px', textAlign: 'center' }}>
                                <div>
                                    <WorkspaceLogoUpload form={form} />
                                </div>
                            </div>
                            <TextInput
                                required
                                label="Workspace name"
                                placeholder="e.g ContentStudio"
                                {...form.getInputProps('name')}
                                mb={16}
                                styles={{
                                    label: {
                                        marginBottom: '8px'
                                    },
                                    input: {
                                        height: '36px'
                                    }
                                }}
                            />

                            <TextInput
                                required
                                label="URL"
                                description="Workspace URL used for the branded call-to-actions"
                                placeholder="e.g https://contentstudio.io"
                                value={form.values.url}
                                onChange={handleURLChange}
                                error={form.errors?.url}
                                mb={24}
                                styles={{
                                    label: {
                                        marginBottom: '8px'
                                    },
                                    input: {
                                        height: '36px'
                                    },
                                    description: {
                                        marginTop: '4px'
                                    }
                                }}
                            />
                            {/* <Text size="md" fw={600} mb={20} style={{ color: '#464e52' }} mt={50}>
                                What Best  <span style={{ fontWeight: 600, color: '#2b70fb' }}>Describes</span> You ?
                            </Text> */}
                            <Select
                                label="What best describes you?"
                                required
                                placeholder="Choose one"
                                data={[
                                    { value: 'Blogger', label: 'Blogger' },
                                    { value: 'Solopreneur', label: 'Solopreneur' },
                                    { value: 'Agency', label: 'Agency' },
                                    { value: 'SaaS Business', label: 'SaaS Business' },
                                    { value: 'Marketing Team', label: 'Marketing Team' },
                                    { value: 'Enterprise', label: 'Enterprise' },
                                ]}
                                {...form.getInputProps('type')}
                                mb={24}
                                styles={{
                                    label: {
                                        marginBottom: '8px'
                                    },
                                    input: {
                                        height: '36px'
                                    }
                                }}
                            />

                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '24px',
                            paddingTop: '50px',
                        }}>
                            <Button
                                color="dark"
                                leftSection={<span>📅</span>}
                                onClick={() => window.open('https://replug.youcanbook.me/', '_blank')}
                                styles={{
                                    root: {
                                        color: '#fff',
                                        fontWeight: 'normal'
                                    }
                                }}
                            >
                                Book a demo
                            </Button>
                            <Button
                                type="submit"
                                loading={loading}
                            >
                                Start your trial
                            </Button>
                        </div>
                    </form>
                </Grid.Col>
            </Grid>
        </Modal>
    );
}