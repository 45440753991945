import { QRCodeModal } from '@/components/Modals/QRCodeModal/QRCodeModal';
import { Navbar } from '@/components/Navbar/Navbar';
import { UserService } from '@/services/UserService';
import { WorkspaceService } from '@/services/WorkspaceService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useCustomDomainsStore } from '@/stores/useCustomDomainsStore';
import { useTagsStore } from '@/stores/useTagsStore';
import { useUTMStore } from '@/stores/useUTMStore';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWorkspace } from '@/types/workspace';
import { Box, Container, Flex, Loader, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isAccountActive } from '@/utils/CommonUtility';
import { usePlanStore } from '@/stores/usePlanStore';
import { useIpAddressStore } from '@/stores/useIpAddressStore';
import { BillingBanner } from '@/components/Billing/BillingBanner';
import { DisabledAccountMessage } from '@/components/Billing/DisabledAccountMessage';
import { DiscountBanner } from '@/components/DiscountBanner/DiscountBanner';
import { useCombinedUsermaven } from '@/hooks/useCombinedUsermaven';
import { isWhitelabelDomain } from '@/utils/CommonUtility';
import { useLocation } from 'react-router-dom';

function MainLayout({ children, header = true }: { children: React.ReactNode; header?: boolean }) {
  const { workspaceId } = useParams();
  const [user,setUser] = useUserStore((state) => [state.user,state.setUser]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [setWorkspaces, defaultWorkspace, setDefaultWorkspace] = useWorkspaceStore((state) => [
    state.setWorkspaces,
    state.defaultWorkspace,
    state.setDefaultWorkspace,
  ]);
  const [plan, fetchPlan, fetchPrimarySubscription] = usePlanStore((state) => [state.plan, state.fetchPlan, state.fetchPrimarySubscription]);
  const [fetchTags] = useTagsStore((state) => [state.fetchTags]);
  const [fetchIpAddresses] = useIpAddressStore((state) => [state.fetchIpAddresses]);
  const [fetchUTMs] = useUTMStore((state) => [state.fetchUTMs]);
  const [fetchCampaigns] = useCampaignsStore((state) => [state.fetchCampaigns]);
  const [fetchCustomDomains] = useCustomDomainsStore((state) => [state.fetchCustomDomains]);
  const [isLoading, setLoading] = useState(true);
  const [isWorkspaceLoading, setIsWorkspaceLoading] = useState(true);
  const navigate = useNavigate();
  const [setDefaultPermissions] = usePermissionsStore((state) => [state.setDefaultPermissions]);
  const { id } = useCombinedUsermaven(); // import methods from useJitsu hook
  const location = useLocation(); // Add this to track URL changes

  useEffect(() => {
    if (plan?.used_limits && user) {
      const {_id, email, first_name, last_name, created_at} = user
      const organization = defaultWorkspace?.organization

      const {display_name, _id: plan_id, type_of} = plan

      const {
        workspaces, campaigns, links, retargeting_pixels, custom_domains, clicks, 
        team_members, bio_links
      } = plan.used_limits

      // Creating Playload
      const payload = {
        // Required attributes
        id: _id, // Unique ID for the user in database.
        email: email, // Email address for the user.

        // Recommended attributes
        created_at: created_at,

        first_name: first_name,
        last_name: last_name,

        // Company Attributes
        company: {
          // Required Attributes
          id: organization?._id || '', // Company ID
          name: organization?.name || '', // Company Name.
          created_at: organization?.created_at || '',

          custom: {}
        },

        custom: {
          current_plan: display_name,
          subscription_id: plan_id,
          subscription_type_of: type_of,

          total_workspaces: workspaces || 0,
          total_campaigns: campaigns || 0,
          total_links: links || 0,
          total_pixels: retargeting_pixels || 0,
          total_custom_domains: custom_domains || 0,
          total_team_members: team_members || 0,
          total_clicks: clicks || 0,
          bio_links: bio_links || 0,
        }
      }
      id({...payload})
    }
  }, [plan]);

  useEffect(() => {
    if (!isWorkspaceLoading && defaultWorkspace) {
      // List of allowed paths even when account is inactive
      const allowedPaths = [
        '/account/billing',
        // '/account/profile',
        '/logout',
        // '/refer&earn',
        '/verification'
      ];
      // Check if current path is allowed
      const isAllowedPath = allowedPaths.some(path => location.pathname.includes(path));
      // Use the utility function to check account status
      const isActive = isAccountActive(defaultWorkspace?.organization);
      // Redirect to billing if account is inactive and not on an allowed path
      if (!isActive && !isAllowedPath) {
        navigate('/account/billing');
      }
    }
  }, [location.pathname, defaultWorkspace, isWorkspaceLoading]);

  const fetchProfile = async () => {
    const res = await new UserService()
      .getProfile()
      .then((response) => {
        if (response.data.status) {
          setUser(response.data.data.user);
          console.log('Debug: User', response.data.data.user);
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.debug(error);
        return false;
      });

    if (!res) {
      // redirect to login page with redirect url including current path and query params
      const redirect = window.location.pathname + window.location.search;
      navigate('/login?redirect=' + redirect);
    }
    setLoading(false);
  };

  const fetchWorkspaces = async () => {
    await new WorkspaceService()
      .getAll()
      .then((res) => {
        // find default workspace
        let defaultWorkspace: IWorkspace | null = res.find(
          (item: IWorkspace) => item.is_default === true
        );

        // If no default workspace is found, set the first workspace as default workspace.
        if (!defaultWorkspace) {
          // eslint-disable-next-line prefer-destructuring
          defaultWorkspace = res[0];
        }

        // If the workspaceId is available in the route, redirect the user to that workspace.

        if (workspaceId) {
          const workspace = res.find((item: IWorkspace) => item._id === workspaceId);
          if (workspace) {
            defaultWorkspace = workspace;
          }
        }
        // set all workspaces and default workspace

        setWorkspaces(res);
        setDefaultWorkspace(defaultWorkspace);


        // NOTE: it was redirecting the user in all cases.
        // navigate(`/dashboard/${defaultWorkspace?._id}`);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsWorkspaceLoading(false);
  };

  const IsUserAccountActive = defaultWorkspace?.organization?.status === 'active';

  useEffect(() => {
    fetchProfile();
    fetchWorkspaces();
  }, []);

  useEffect(() => {
    if (!isWorkspaceLoading && defaultWorkspace?._id) {
      fetchPlan(defaultWorkspace._id, defaultWorkspace?.organization_id || '');
      fetchPrimarySubscription(defaultWorkspace._id, defaultWorkspace?.organization_id || '');
      fetchTags(workspaceId || defaultWorkspace?._id || '');
      fetchIpAddresses(workspaceId || defaultWorkspace?._id || '');
      fetchCampaigns(workspaceId || defaultWorkspace?._id || '');
      fetchUTMs(workspaceId || defaultWorkspace?._id || '');
      fetchCustomDomains(workspaceId || defaultWorkspace?._id || '');
      setDefaultPermissions(defaultWorkspace?._id || '')
    }
  }, [defaultWorkspace?._id, isWorkspaceLoading]);

  if (isLoading || isWorkspaceLoading) {
    return (
      <Container h={600}>
        <Flex h={'100%'} align={'center'} justify={'center'}>
          <Flex align={'center'} direction={'column'}>
            <Loader />
            <Text mt={16} size="sm">
              Loading...
            </Text>
          </Flex>
        </Flex>
      </Container>
    );
  }

  return (
    <>
      <Box
        style={{
          height: '100%',
          backgroundColor: '#f2f6f7',
        }}
      >
        {/** If user account is not active, show the appropriate message */}
        <QRCodeModal />
        {!isWhitelabelDomain() && <DiscountBanner/>}
        {defaultPermissions && checkPermission(defaultPermissions, ['view_plan']) && header && (
          <BillingBanner />
        )}
        {header && <Navbar />}
        {children}
        {/* {!IsUserAccountActive ? (
          <DisabledAccountMessage />
        ) : (
          <>
          </>
        )} */}
      </Box>
    </>
  );
}

export default MainLayout;
