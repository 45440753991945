import { APP_ENVIRONMENT, PADDLE_PLAN_IDS } from '@/constants/Constants';
import ReplugLogo from '../assets/images/logos/replug-logo.svg';
import { REPLUG_PRIVACY_URL, REPLUG_TERMS_URL } from '@/constants/Constants';

export const checkPermission = (permissions: string|any, check_permission: string[]|any, invert: boolean = true) => {
    //@ts-ignore
    return invert ? (check_permission.some(permission => 
      //@ts-ignore
      permissions.some(item => item?.name === permission)
    )) : 
    //@ts-ignore
    !(check_permission.some(permission => 
      //@ts-ignore
      permissions.some(item => item?.name === permission)
    ))
};

export const isUserCollaborator = (user: string[]|any, workspace_id: string|any, payload: any, type: string) => {
  //@ts-ignore
  const workspace_member = user?.workspace_member?.find(member => member.workspace_id === workspace_id);
  if (workspace_member?.role === 'collaborator') {
    switch (type) {
      case "campaign":
      case "link":
      case "rss":
      case "bio_link":
      case "tag":
      case "pixel":
      case "widget":
      case "utm":
      case "exclude_traffic":
      case "IpAddress":
          if (workspace_member.user_id !== payload.user_id) {
            // console.log('is user collaborator payload: ', payload);
            return false
          }
          break
      }
    }
    return true
};

export const isUserOwner = (user: string[] | any, workspace_id: string | any) => {
  // Find the workspace member record for this workspace
  //@ts-ignore
  const workspace_member = user?.workspace_member?.find(member => member.workspace_id === workspace_id);
  // Return true if the user's role is 'owner', false otherwise
  return workspace_member?.role === 'owner';
};

/**
 * Get paddle plan id according to the environment
 * @param plan
 * @returns string
 */
export const getPaddlePlanId = (plan: { name: keyof typeof PADDLE_PLAN_IDS['sandbox'] }, workspaces: string) => {
  const planName = getPlanName(plan, workspaces) as keyof typeof PADDLE_PLAN_IDS['sandbox'];
  const environment = APP_ENVIRONMENT !== 'production' ? 'sandbox' : 'production';
  return PADDLE_PLAN_IDS[environment][planName];
};

/**
 * Get plan name
 * @param plan
 * @returns string
 */
export const getPlanName = (plan: { name: keyof typeof PADDLE_PLAN_IDS['sandbox'] }, workspaces: string) => {
  if (!plan.name?.includes('agency')) {
    return plan.name;
  }
  switch (workspaces) {
    case '20':
      return plan.name + '-20-workspaces';
    case '50':
      return plan.name + '-50-workspaces';
    case '100':
      return plan.name + '-100-workspaces';
    case '10':
    default:
      return plan.name;
  }
}

/**
 * Get plan trial name
 * @param plan
 * @returns string
 */
export const getPlanTrialName = (plan: any, workspaces: string) => {
  if (!plan.trial_name?.includes('agency')) {
    return plan.trial_name;
  }
  switch (workspaces) {
    case '20':
      return plan.trial_name + '-20-workspaces';
    case '50':
      return plan.trial_name + '-50-workspaces';
    case '100':
      return plan.trial_name + '-100-workspaces';
    case '10':
    default:
      return plan.trial_name;
  }
}

/**
 * Check if the workspace is locked or not using the organization status
 * @param workspace 
 * @returns 
 */
export const isWorkspaceLocked = (workspace: any) => {
  return (workspace?.organization?.status && workspace.organization.status !== 'active') ? true : false;
};

/**
 * Get app logo according to the whitelabel status
 * @returns string
 */
export const getAppLogo = () => {
  if(!isWhitelabelDomain()) {
    return ReplugLogo;
  }
  const whitelabel = JSON?.parse(localStorage.getItem('replug-whitelable') || '{}');
  if (whitelabel?.is_whitelabeled && whitelabel?.logo) {
    return whitelabel.logo;
  }
  return ReplugLogo;
}

/**
 * Get privacy policy link according to the whitelabel status
 * @returns string
 */
export const getAppPrivacyPolicy = () => {
  if(!isWhitelabelDomain()) {
    return REPLUG_PRIVACY_URL;
  }
  const whitelabel = JSON?.parse(localStorage.getItem('replug-whitelable') || '{}');
  if (whitelabel?.is_whitelabeled && whitelabel?.privacy_policy) {
    return whitelabel.privacy_policy;
  }
  return REPLUG_PRIVACY_URL;
}

export const getAppTermsOfService = () => {
  if(!isWhitelabelDomain()) {
    return REPLUG_TERMS_URL;
  }
  const whitelabel = JSON?.parse(localStorage.getItem('replug-whitelable') || '{}');
  if (whitelabel?.is_whitelabeled && whitelabel?.terms_of_service) {
    return whitelabel.terms_of_service;
  }
  return REPLUG_TERMS_URL;
}

/**
 * Is whitelabel domain
 * @returns boolean
 */
export const isWhitelabelDomain = () => {
  return !window.location.hostname.includes('.replug.io') && !window.location.hostname.includes('localhost');
};

/**
 * Save whitelabel settings to local storage and update favicon and title
 * @param whitelabel
 * @param updateTitle
 * @returns void
 */
export const saveWhitelabelSettings = (whitelabel: any, updateTitle = false) => {
  localStorage.setItem("replug-whitelable", JSON.stringify(whitelabel));
  const link = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  if (link) {
    link.href = whitelabel?.favicon;
  }
  if (updateTitle) {
    document.title = whitelabel?.name || 'Replug';
  }
}

/**
 * Format limit
 * @param limit 
 * @returns 
 */
export const formatLimit = (limit: number, limitType: string = '') => {
  if (limitType === 'clicks') {
    if (limit >= 10000000) {
      return 'Unlimited';
    }
    return limit;
  }
  if (limit >= 1000) {
    return 'Unlimited';
  }
  return limit;
};

/**
   * Get progress bar value
   * @param limitType
   * @returns
   */
export const getPlanLimitsProgressValue = (plan: any, limitType: string) => {
  // @ts-ignore
  if (plan?.limits?.[limitType] == 0) {
    return 100;
  }
  // @ts-ignore
  return (plan?.used_limits?.[limitType] ?? 0) / (plan?.limits?.[limitType] ?? 1) * 100;
};

/**
 * Get progress bar color
 * @param limitType
 */
export const getPlanLimitsProgressColor = (plan: any, limitType: string) => {
  return getPlanLimitsProgressValue(plan, limitType) > 75 ? 'red' : 'blue';
};

/**
 * check if the account is locked or open
 */
export const isAccountActive = (organization: any) => {
  // If there's a cancellation date, check if it's in the future
  if (organization?.cancellation_effective_at) {
    const cancellationDate = new Date(organization.cancellation_effective_at);
    const today = new Date();
    // Account is still active if cancellation date is in the future
    if (cancellationDate > today) {
      return true;
    }
  }
  // If no cancellation date or it's passed, check regular status
  return organization?.status === 'active';
};
