import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faChevronDown, faGear, faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Avatar,
  Button,
  Divider,
  Flex,
  Group,
  Menu,
  ScrollArea,
  Text,
  UnstyledButton,
  rem,
} from '@mantine/core';
import { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './workspace-menu.module.css';
import { WorkspaceCreateModal } from '@/components/Modals/WorkspaceCreateModal/WorkspaceCreateModal';
import { ManageWorkspacesModal } from '@/components/Modals/ManageWorkspacesModal/ManageWorkspacesModal';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserOwner, isWorkspaceLocked } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { OnboardingModal } from '@/components/Modals/OnboardingModal/OnboardingModal';
import { UserService } from '@/services/UserService';
import { notifications } from '@mantine/notifications';
interface WorkspaceButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string;
  name: string;
  icon?: React.ReactNode;
}

const WorkspaceButton = forwardRef<HTMLButtonElement, WorkspaceButtonProps>(
  ({ image, name, icon, ...others }: WorkspaceButtonProps, ref) => (
    <UnstyledButton
      style={{
        minWidth: rem(200),
        backgroundColor: 'var(--mantine-color-primary-8)',
        padding: '0.25rem',
        borderRadius: 'var(--mantine-radius-sm)',
        color: '#fff',
        ':hover': {
          backgroundColor: '#242424',
          color: '#fff',
        },
      }}
      className={classes.root}
      ref={ref}
      {...others}
    >
      <Flex align={'center'}>
        <Avatar color="white" src={image} radius="xl" size="sm" />
        <Flex
          style={{
            flexGrow: 1,
          }}
        >
          <Text size="sm" fw={500} ml={'xs'} tt={'capitalize'}>
            {/* {name} */}
            {name.split(' ').slice(0, 2).join(' ')}
            {name.split(' ').length > 2 ? '...' : ''}
          </Text>
        </Flex>

        <Flex mr="xs" justify={'end'}>
          <FontAwesomeIcon size="xs" icon={faChevronDown} />
        </Flex>

        {/* {icon || <IconChevronRight size="1rem" />} */}
      </Flex>
    </UnstyledButton>
  )
);

export function WorkspaceMenu() {
  const [
    workspaces,
    defaultWorkspace,
    setDefaultWorkspace,
    setCreateToggle,
    setManageWorkspacesToggle,
  ] = useWorkspaceStore((state) => [
    state.workspaces,
    state.defaultWorkspace,
    state.setDefaultWorkspace,
    state.setCreateToggle,
    state.setManageWorkspaceToggle,
  ]);
  const [opened, setOpened] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [loading, setLoading] = useState(false);
  const userService = new UserService();
  const navigate = useNavigate();
  const handleWorkspaceChange = (workspace: any) => {
    // Note: anyone can move in its locked workspace but all the features will be disabled user can only view the billing page to cahnge his card or subscription
    // if (isWorkspaceLocked(workspace)) return;
    setDefaultWorkspace(workspace);
    setOpened(false);
    navigate(`/dashboard/${workspace._id}`);
  };
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);

  const handleWorkspaceSettings = (workspace: any) => {
    setDefaultWorkspace(workspace);
    setOpened(false);
    navigate(`/workspace/${defaultWorkspace?._id}/basic`);
  };

  const handleNewWorkspace = async () => {
    try {
      setLoading(true);
      const response = await userService.checkUserOrganizationStatus();

      if (response.data.status) {
        if (response.data.data.needs_onboarding) {
          setOpened(false);
          setShowOnboarding(true);
        } else {
          setOpened(false);
          setCreateToggle(true);
        }
      }
    } catch (error: any) {
      notifications.show({
        color: 'red',
        message: error.response?.data?.message || 'Failed to check user status'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ManageWorkspacesModal />
      <WorkspaceCreateModal />
      <OnboardingModal
        opened={showOnboarding}
        onClose={() => setShowOnboarding(false)}
      />
      <Menu withArrow shadow="xs" opened={opened} onChange={setOpened}>
        <Menu.Target>
          <WorkspaceButton
            image={defaultWorkspace?.logo || ''}
            name={defaultWorkspace?.name || ''}
          />
        </Menu.Target>
        <Menu.Dropdown mah={400} w={270}>
          <>
            <Flex align="center" py={4}>
              <Flex
                style={{
                  flexGrow: 1,
                }}
              >
                <Text fw={600} size="sm" p={8}>
                  Workspaces
                </Text>
              </Flex>

              {(isUserOwner(user, defaultWorkspace?._id) || !defaultWorkspace?.agency?.is_whitelabel_enabled) && (
                <Button
                  size="xs"
                  variant="subtle"
                  onClick={handleNewWorkspace}
                >
                  + New Workspace
                </Button>
              )}
            </Flex>
          </>
          <Divider color="gray.2" />
          <ScrollArea
            style={{
              height: workspaces.length > 5 ? '300px' : 'auto',
            }}
          >
            {workspaces.map((workspace, index) => (
              <Menu.Item
                onClick={() => handleWorkspaceChange(workspace)}
                className={classes.menuItem}
                key={workspace._id}
                py={12}
                style={{
                  backgroundColor:
                    workspace._id === defaultWorkspace?._id ? 'var(--mantine-color-primary-1)' : '',
                  borderLeft: `4px solid ${workspace._id === defaultWorkspace?._id ? '#007aff' : 'transparent'
                    }`,
                }}
              >
                <Flex align="center" justify="space-between">
                  <Flex>
                    <Avatar size="sm" src={workspace.logo} mr={10} />
                    <Text size="sm">
                      {workspace.name.split(' ').slice(0, 3).join(' ')}
                      {workspace.name.split(' ').length > 3 ? '...' : ''}
                    </Text>
                  </Flex>

                  {isWorkspaceLocked(workspace) ? (
                    <ActionIcon variant="subtle">
                      <FontAwesomeIcon icon={faLock} />
                    </ActionIcon>
                  ) : (
                    defaultPermissions && checkPermission(defaultPermissions, ['edit_workspace']) && (
                      <Group className={classes.menuItemSettings}>
                        <ActionIcon
                          variant="subtle"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            handleWorkspaceSettings(workspace);
                          }}
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </ActionIcon>
                      </Group>
                    )
                  )}
                </Flex>
              </Menu.Item>
            ))}
          </ScrollArea>
          <Divider color="gray.2" />
          <Menu.Item py={12}>
            <Flex align="center" justify="center">
              <Button
                variant="outline"
                size="xs"
                color="gray.7"
                onClick={() => setManageWorkspacesToggle(true)}
              >
                + Manage Workspaces
              </Button>
            </Flex>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
