import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useBioSocialBlockStore } from '@/stores/Bio/useBioSocialBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Fieldset,
  Flex,
  Group,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  BIO_LINKS_SOCIAL_NETWORKS,
  DEFAULT_SOCIAL_BLOCK_VALUES,
  SOCIAL_NETWORK_URLS,
  findSocialNetworkByValue,
} from '@replug/utils';
import { useParams } from 'react-router-dom';
import { ScheduleSection } from '../ScheduleSection';
import { useEffect, useState } from 'react';
import { AnimationSection } from '../AnimationSection';
import { CustomAppearanceSection } from '../CustomAppearanceSection';

export const BioSocialSection = ({ reload }: { reload: () => void }) => {
  const { id } = useParams();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [bioSocialBlock, setBioSocialBlock] = useBioSocialBlockStore((state) => [
    state.bioSocialBlock,
    state.setBioSocialBlock,
  ]);

  const [bioLink, create] = useBioLinkSetupStore((state) => [state.bioLink, state.create]);

  // Schedule state variables
  const [isScheduleEnabled, setIsScheduleEnabled] = useState<boolean>(bioSocialBlock.is_schedule_enabled || false);
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    bioSocialBlock.schedule_start_at ? new Date(bioSocialBlock.schedule_start_at) : null
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    bioSocialBlock.schedule_end_at ? new Date(bioSocialBlock.schedule_end_at) : null
  );
  const [timezone, setTimezone] = useState<string>(bioSocialBlock.schedule_timezone || 'UTC');
  // Animation state variables
  const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(bioSocialBlock.is_animation_enabled || false);
  const [animationType, setAnimationType] = useState<string>(bioSocialBlock.animation_type || 'tada');
  // Custom Appearance state variables
  const [isCustomAppearanceEnabled, setIsCustomAppearanceEnabled] = useState<boolean>(
    bioSocialBlock.is_custom_appearance_enabled || false
  );
  const [customAppearance, setCustomAppearance] = useState(
    bioSocialBlock.custom_appearance_attributes || DEFAULT_SOCIAL_BLOCK_VALUES.custom_appearance_attributes
  );

  const handleSocialSelection = (value: string, toggle: boolean) => {
    // only at max 8 social media platforms can be selected

    if (bioSocialBlock.channels_selected.length === 8 && toggle) {
      notifications.show({
        color: 'red',
        message: `You can only select up to 8 social media platforms`,
      });
      return;
    }

    const oldValues: any = bioSocialBlock?.channels_selected;
    const newValues = !toggle
      ? oldValues.filter((item: string) => item !== value)
      : [...oldValues, value];

    // Modify the channels_urls based on the selection or deselection
    const updatedUrls = { ...bioSocialBlock.channels_urls };
    if (toggle) {
      // Set the URL for a newly selected channel
      // @ts-ignore
      updatedUrls[value] = SOCIAL_NETWORK_URLS[value];
    } else {
      // Remove the URL for a deselected channel
      delete updatedUrls[value];
    }

    setBioSocialBlock({
      ...bioSocialBlock,
      channels_urls: updatedUrls,
      channels_selected: newValues,
    });
  };

  const handleAddBlock = async () => {
    // validate the channel urls. Notify the mising ones
    if (bioSocialBlock.channels_selected.length === 0) {
      notifications.show({
        color: 'red',
        message: `Please select at least one social media platform`,
      });
      return;
    }

    if (!bioSocialBlock.title && bioSocialBlock.is_show_title_enabled) {
      notifications.show({
        color: 'red',
        message: `Please add a title for the social block`,
      });
      return;
    }

    const isUrlMissing = bioSocialBlock.channels_selected.some((channel: string) => {
      if (!bioSocialBlock.channels_urls[channel]) {
        notifications.show({
          color: 'red',
          message: `Please add a url for ${channel}`,
        });

        return true;
      }
      if (
        bioSocialBlock.channels_urls[channel] &&
        !bioSocialBlock.channels_urls[channel].includes('http')
      ) {
        notifications.show({
          color: 'red',
          message: `Please add a valid url for ${channel}`,
        });

        return true;
      }
      return false;
    });

    if (!isUrlMissing) {
      let payload = {
        ...bioSocialBlock,
        bio_link_id: id,
        workspace_id: defaultWorkspace?._id,
        is_schedule_enabled: isScheduleEnabled,
        schedule_start_at: startDateTime ? startDateTime.toISOString() : null,
        schedule_end_at: endDateTime ? endDateTime.toISOString() : null,
        schedule_timezone: timezone,
        // Animation fields
        is_animation_enabled: isAnimationEnabled,
        animation_type: animationType,
        // Custom Appearance fields
        is_custom_appearance_enabled: isCustomAppearanceEnabled,
        custom_appearance_attributes: customAppearance
      };

      if (bioSocialBlock._id) {
        new BioLinkBlockService()
          .update(defaultWorkspace?._id || '', payload)
          .then((res) => {
            if (res.data.data) {
              notifications.show({
                color: 'green',
                message: res.data.message,
              });

              setBioSocialBlock(DEFAULT_SOCIAL_BLOCK_VALUES);
              reload();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        payload.order = bioLink?.blocks.length + 1;

        if (!bioLink?._id) {
          notifications.show({
            color: 'red',
            message: 'Please first publish your bio link.',
          });
          return;
        }
        new BioLinkBlockService()
          .create(defaultWorkspace?._id || '', payload)
          .then((res) => {
            if (res.data.data) {
              notifications.show({
                color: 'green',
                message: res.data.message,
              });

              setBioSocialBlock(DEFAULT_SOCIAL_BLOCK_VALUES);
              reload();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  // Update schedule-related props when schedule settings change
  useEffect(() => {
    if (isScheduleEnabled) {
      setBioSocialBlock({
        ...bioSocialBlock,
        is_schedule_enabled: isScheduleEnabled,
        schedule_start_at: startDateTime ? startDateTime.toISOString() : null,
        schedule_end_at: endDateTime ? endDateTime.toISOString() : null,
        schedule_timezone: timezone
      });
    } else {
      setBioSocialBlock({
        ...bioSocialBlock,
        is_schedule_enabled: false,
        schedule_start_at: null,
        schedule_end_at: null,
        schedule_timezone: 'UTC'
      });
    }
  }, [isScheduleEnabled, startDateTime, endDateTime, timezone]);

  // Update animation-related props when animation settings change
  useEffect(() => {
    setBioSocialBlock({
      ...bioSocialBlock,
      is_animation_enabled: isAnimationEnabled,
      animation_type: animationType
    });
  }, [isAnimationEnabled, animationType]);

  // Update custom appearance-related props when custom appearance settings change
  useEffect(() => {
    setBioSocialBlock({
      ...bioSocialBlock,
      is_custom_appearance_enabled: isCustomAppearanceEnabled,
      custom_appearance_attributes: customAppearance
    });
  }, [isCustomAppearanceEnabled, customAppearance]);

  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Share all your social media profiles through the Social Icons Block. You can feature up to 8
        of your own social media profiles.
      </Text>
      <Divider />
      <Box p="md">
        <Flex mb={'md'} direction={'column'}>
          <Flex
            align={'flex-end'}
            justify={'end'}
            style={{
              flexGrow: 1,
            }}
          >
            <Switch
              size="xs"
              label="Show title"
              checked={bioSocialBlock?.is_show_title_enabled}
              onChange={(e) => {
                setBioSocialBlock({
                  ...bioSocialBlock,
                  is_show_title_enabled: e.currentTarget.checked,
                });
              }}
            />
          </Flex>
          <TextInput
            w={'100%'}
            label="Add a title"
            placeholder="Your title for the social block"
            value={bioSocialBlock?.title}
            onChange={(e) => {
              setBioSocialBlock({
                ...bioSocialBlock,
                title: e.currentTarget.value,
              });
            }}
          />
        </Flex>
        <Flex justify={'end'}>
          <Switch
            ml={10}
            size="xs"
            label="Open link in new tab"
            checked={bioSocialBlock.open_new_tab ?? true}
            onChange={(e) => {
              setBioSocialBlock({
                ...bioSocialBlock,
                open_new_tab: e.currentTarget.checked,
              });
            }}
          />
        </Flex>

        <Fieldset mb="md" legend="Select up to 8 social media platforms.">
          <Group mt="md">
            {BIO_LINKS_SOCIAL_NETWORKS.map((network) => (
              <Tooltip label={network.name}>
                <ActionIcon
                  variant="outline"
                  color={
                    bioSocialBlock.channels_selected.includes(network.value) ? 'primary' : 'gray.6'
                  }
                  onClick={() => {
                    handleSocialSelection(
                      network.value,
                      bioSocialBlock.channels_selected.includes(network.value) ? false : true
                    );
                  }}
                >
                  {network.icon}
                </ActionIcon>
              </Tooltip>
            ))}
          </Group>
        </Fieldset>
        {bioSocialBlock.channels_selected.length > 0 && (
          <>
            <Fieldset mb={'md'}>
              {bioSocialBlock.channels_selected.map((channel: string) => (
                <Flex align={'center'}>
                  <TextInput
                    mb={'md'}
                    styles={{
                      label: {
                        textTransform: 'capitalize',
                      },
                    }}
                    style={{
                      flexGrow: 1,
                    }}
                    mr={'md'}
                    label={channel}
                    value={bioSocialBlock.channels_urls[channel]}
                    placeholder={`Your ${channel} URL`}
                    onChange={(e) => {
                      setBioSocialBlock({
                        ...bioSocialBlock,
                        channels_urls: {
                          ...bioSocialBlock.channels_urls,
                          [channel]: e.currentTarget.value,
                        },
                      });
                    }}
                    leftSection={<>{findSocialNetworkByValue(channel)?.icon}</>}
                  />
                  <Tooltip label={'Remove'}>
                    <ActionIcon
                      mt={8}
                      variant="outline"
                      color="red"
                      onClick={() => {
                        setBioSocialBlock({
                          ...bioSocialBlock,
                          // remove the key from the channels_urls object
                          channels_urls: Object.keys(bioSocialBlock.channels_urls).reduce(
                            (object: any, key: string) => {
                              if (key !== channel) {
                                object[key] = bioSocialBlock.channels_urls[key];
                              }
                              return object;
                            },
                            {}
                          ),

                          channels_selected: bioSocialBlock.channels_selected.filter(
                            (item: string) => item !== channel
                          ),
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} color="red" size="sm" />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              ))}
            </Fieldset>
          </>
        )}

        {/* Schedule Section */}
        <ScheduleSection
          isScheduleEnabled={isScheduleEnabled}
          setIsScheduleEnabled={setIsScheduleEnabled}
          startDateTime={startDateTime}
          setStartDateTime={setStartDateTime}
          endDateTime={endDateTime}
          setEndDateTime={setEndDateTime}
          timezone={timezone}
          setTimezone={setTimezone}
        />

        {/* Animation Section */}
        <AnimationSection
          isAnimationEnabled={isAnimationEnabled}
          setIsAnimationEnabled={setIsAnimationEnabled}
          animationType={animationType}
          setAnimationType={setAnimationType}
        />

        {/* Custom Appearance Section */}
        <CustomAppearanceSection
          isCustomAppearanceEnabled={isCustomAppearanceEnabled}
          setIsCustomAppearanceEnabled={setIsCustomAppearanceEnabled}
          customAppearance={customAppearance}
          setCustomAppearance={setCustomAppearance}
          showButtonSection={true}
        />

        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioSocialBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};
