import { WorkspaceLogoUpload } from '@/components/ImageUploaders/WorkspaceLogoUpload';
import { WorkspaceService } from '@/services/WorkspaceService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWorkspace } from '@/types/workspace';
import { ensureHttps } from '@/utils/LinkUtility';
import { Box, Button, Flex, Grid, Modal, Space, TextInput, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { isWorkspaceLocked } from '@/utils/CommonUtility';
import { useNavigate } from 'react-router-dom';
import { useCombinedUsermaven } from '@/hooks/useCombinedUsermaven';

const schema = z.object({
  _id: z.string().nullable(),
  name: z.string().min(2, { message: 'Name should have at least 2 letters' }).max(100, { message: 'Name cannot exceed 100 characters' }),
  url: z.string().url({ message: 'Invalid URL' }).min(2, { message: 'URL is required.' }),
  logo: z.nullable(z.string()),
});
export function WorkspaceCreateModal({ onCreate }: { onCreate?: (workspace: IWorkspace) => void }) {
  const [loading, setLoading] = useState(false);
  const {track} = useCombinedUsermaven()
  const navigate = useNavigate();
  const [createToggle, setCreateToggle, workspaces, setWorkspaces, setDefaultWorkspace, setManageWorkspacesToggle] = useWorkspaceStore((state) => [
    state.createToggle,
    state.setCreateToggle,
    state.workspaces,
    state.setWorkspaces,
    state.setDefaultWorkspace,
    state.setManageWorkspaceToggle
  ]);
  const form = useForm({
    initialValues: {
      _id: null,
      name: '',
      url: '',
      logo: null,
    },
    validate: zodResolver(schema),
  });
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  // Custom onChange handler for the URL field
  const handleURLChange = (event: any) => {
    let value = event.currentTarget.value;

    // Check if the URL does not start with http:// or https://
    if (value) {
      form.setFieldValue('url', ensureHttps(value)); // Update the form value
    } else {
      form.setFieldValue('url', value); // Update the form value
    }
  };

  const handleCreate = async (formValues: any) => {
    setLoading(true);
    await new WorkspaceService()
      .create(defaultWorkspace?._id || '', formValues)
      .then((res) => {
        if (res.data.status) {
          setCreateToggle(false);
          // Add new workspace to the list
          const { workspace } = res.data.data;
          const workspaceItem: IWorkspace = {
            ...workspace,
            is_default: res.data.data.is_default,
            role: res.data.data.role,
          };
          setWorkspaces([...workspaces, workspaceItem]);
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          track('workspace_created', {workspace_name: workspace.name, workspace_id: workspace._id})
          handleWorkspaceChange(workspace)
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 ||  err.response.status ===  422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
        if (err.response.status === 403) {
          notifications.show({
            color: 'red',
            message: 'You are not authorized to create a workspace as being a team member here in this workspace.',
          });
        }
        console.log(err);
      });
    setLoading(false);
  };

  const handleWorkspaceChange = (workspace: any) => {
    if (isWorkspaceLocked(workspace)) return;
    setDefaultWorkspace(workspace);
    setManageWorkspacesToggle(false)
    navigate(`/dashboard/${workspace._id}`);
  };

  useEffect(() => {
    if (!createToggle) {
      form.reset();
    }
  }, [createToggle]);
  return (
    <>
      <Modal
        size={640}
        title={<Title order={4}>Create Workspace</Title>}
        opened={createToggle}
        onClose={() => setCreateToggle(false)}
      >
        <Grid>
          <Grid.Col span={5}>
            <WorkspaceLogoUpload form={form} />
          </Grid.Col>
          <Grid.Col span={7}>
            <Box mr={16}>
              <form onSubmit={form.onSubmit(handleCreate)}>
                <TextInput
                  mb={12}
                  label="Workspace Name"
                  required
                  placeholder="e.g ContentStudio"
                  variant="filled"
                  {...form.getInputProps('name')}
                />
                <TextInput
                  mb={12}
                  label="URL"
                  required
                  description="Workspace URL used for the branded call-to-actions."
                  placeholder="e.g https://contentstudio.io"
                  variant="filled"
                  value={form.values.url}
                  onChange={handleURLChange}
                  error={form.errors?.url}
                />
                <Flex pb={'sm'} pt={'sm'}>
                  <Button
                    onClick={() => setCreateToggle(false)}
                    variant="subtle"
                    color="gray"
                    fullWidth
                  >
                    Cancel
                  </Button>
                  <Space w={8} />
                  <Button disabled={loading} loading={loading} fullWidth type="submit">
                    Create
                  </Button>
                </Flex>
              </form>
            </Box>
          </Grid.Col>
        </Grid>
      </Modal>
    </>
  );
}
