import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { Box, Text, TextInput, ColorInput, Image, Button, Center, Group, Grid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { UtilsService } from '@/services/UtilsService';
import classes from '../LinkCreationModal.module.css';

export const CustomizeSummaryPage = () => {
    const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
    const [loadingImage, setLoadingImage] = useState(false);

    const handleFileDrop = async (files: any) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('input_file', files[0]);
            setLoadingImage(true);

            await new UtilsService()
                .uploadImage(formData)
                .then((res) => {
                    if (res.data.status) {
                        setLink({
                            ...link,
                            summary_page_attributes: {
                                ...link.summary_page_attributes,
                                image: res.data.url,
                            },
                        });
                    }
                })
                .catch((err) => {
                    if (err.response.status === 400 || err.response.status === 422) {
                        notifications.show({
                            color: 'red',
                            message: err.response.data.message,
                        });
                    }
                });

            setLoadingImage(false);
        }
    };

    return (
        <Box p={16} className={!link.is_summary_page_enabled ? classes.disabled : ''}>
            <Text fw={600} fz={14}>
                Customize Summary Page
            </Text>

            <TextInput
                label="Title"
                value={link.summary_page_attributes?.title || ''}
                variant="filled"
                mt={12}
                placeholder="Enter title for summary page"
                onChange={(event) => {
                    setLink({
                        ...link,
                        summary_page_attributes: {
                            ...link.summary_page_attributes,
                            title: event.currentTarget.value,
                        },
                    });
                }}
            />

            <TextInput
                label="Description"
                value={link.summary_page_attributes?.description || ''}
                variant="filled"
                mt={12}
                placeholder="Enter description for summary page"
                onChange={(event) => {
                    setLink({
                        ...link,
                        summary_page_attributes: {
                            ...link.summary_page_attributes,
                            description: event.currentTarget.value,
                        },
                    });
                }}
            />

            <TextInput
                label="Button Text"
                value={link.summary_page_attributes?.button_text || ''}
                variant="filled"
                mt={12}
                placeholder="Enter button text"
                onChange={(event) => {
                    setLink({
                        ...link,
                        summary_page_attributes: {
                            ...link.summary_page_attributes,
                            button_text: event.currentTarget.value,
                        },
                    });
                }}
            />

            <Box mt={16}>
                <Text size="sm" fw={500} mb={8}>Customize Colors</Text>
                <Grid>
                    <Grid.Col span={6}>
                        <ColorInput
                            label="Background Color"
                            value={link.summary_page_attributes?.background_color || '#FFFFFF'}
                            onChange={(color) => {
                                setLink({
                                    ...link,
                                    summary_page_attributes: {
                                        ...link.summary_page_attributes,
                                        background_color: color,
                                    },
                                });
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <ColorInput
                            label="Text Color"
                            value={link.summary_page_attributes?.text_color || '#333333'}
                            onChange={(color) => {
                                setLink({
                                    ...link,
                                    summary_page_attributes: {
                                        ...link.summary_page_attributes,
                                        text_color: color,
                                    },
                                });
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <ColorInput
                            label="Button Background Color"
                            value={link.summary_page_attributes?.button_bg_color || '#003aa9'}
                            onChange={(color) => {
                                setLink({
                                    ...link,
                                    summary_page_attributes: {
                                        ...link.summary_page_attributes,
                                        button_bg_color: color,
                                    },
                                });
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <ColorInput
                            label="Button Text Color"
                            value={link.summary_page_attributes?.button_text_color || '#F5F5DC'}
                            onChange={(color) => {
                                setLink({
                                    ...link,
                                    summary_page_attributes: {
                                        ...link.summary_page_attributes,
                                        button_text_color: color,
                                    },
                                });
                            }}
                        />
                    </Grid.Col>
                </Grid>
            </Box>

            {link.summary_page_attributes?.image ? (
                <>
                    <Image
                        mt={12}
                        w={480}
                        h={255}
                        fit="contain"
                        style={{
                            borderRadius: '4px',
                            border: '1px solid var(--mantine-color-gray-3)',
                        }}
                        src={link.summary_page_attributes.image}
                        alt="Summary Page Image"
                    />
                    <Center>
                        <Button
                            variant="outline"
                            mt={12}
                            color="red"
                            onClick={() => {
                                setLink({
                                    ...link,
                                    summary_page_attributes: {
                                        ...link.summary_page_attributes,
                                        image: '',
                                    },
                                });
                            }}
                        >
                            Remove Image
                        </Button>
                    </Center>
                </>
            ) : (
                <Dropzone
                    mt={12}
                    onDrop={handleFileDrop}
                    maxSize={5 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    loading={loadingImage}
                >
                    <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                        <Dropzone.Accept>
                            <FontAwesomeIcon icon={faUpload} />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <FontAwesomeIcon icon={faTimes} />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <FontAwesomeIcon icon={faImage} />
                        </Dropzone.Idle>

                        <div>
                            <Text size="sm" inline>
                                Drag image here or click to select file
                            </Text>
                        </div>
                    </Group>
                </Dropzone>
            )}
        </Box>
    );
};