import { UserService } from '@/services/UserService';
import { Container, Flex, Loader, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAuthTokenLocalStorage } from '@/utils/Storage';
import { OnboardingModal } from '@/components/Modals/OnboardingModal/OnboardingModal';

export function HomePage() {
  const [isLoading, setLoading] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProfile = async () => {
    const res = await new UserService()
      .getProfile()
      .then((response) => {
        if (response.data.status) {
          return response.data.data.user;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });

    const hasWorkspace = res.workspace_member && res.workspace_member.length > 0;

    if (!res.email_verified_at) {
      navigate('/verification');
    } else if (!hasWorkspace) {
      // Show forced onboarding modal for users without workspace
      setShowOnboarding(true);
      // Keep loading state false so the UI shows
      setLoading(false);
      return;
    } else if (res) {
      navigate('/dashboard/');
    }
    setLoading(false);
  };

  const handleLoginFromQueryParam = async () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (token) {
      try {
        // Save the token
        setAuthTokenLocalStorage(token);
        return true;
      } catch (error) {
        console.error('Login via token failed:', error);
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const performLoginAndFetch = async () => {
      const didLogin = await handleLoginFromQueryParam();
      if (didLogin || localStorage.getItem('auth_token')) {
        await fetchProfile();
      } else {
        navigate('/login');
      }
    };

    performLoginAndFetch();
  }, []);

  if (isLoading) {
    return (
      <Container h={600}>
        <Flex h={'100%'} align={'center'} justify={'center'}>
          <Flex align={'center'} direction={'column'}>
            <Loader />
            <Text mt={16} size="sm">
              Loading...
            </Text>
          </Flex>
        </Flex>
      </Container>
    );
  }

  return (
    <>
      <Container h={600}>
        <Flex h={'100%'} align={'center'} justify={'center'}>
          <Flex align={'center'} direction={'column'}>
            <Text size="lg">Setting up your workspace...</Text>
          </Flex>
        </Flex>
      </Container>

      {/* Onboarding modal with forced state */}
      <OnboardingModal
        opened={showOnboarding}
        onClose={() => { }}  // Empty function to prevent closing
        isForced={true}
      />
    </>
  );
}
