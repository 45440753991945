// components/Bio/AnimationSection.tsx
import React from 'react';
import { Box, Fieldset, Flex, Select, Switch, Text } from '@mantine/core';
import { faPlay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AnimationSectionProps {
    isAnimationEnabled: boolean;
    setIsAnimationEnabled: (value: boolean) => void;
    animationType: string;
    setAnimationType: (value: string) => void;
}

export const AnimationSection: React.FC<AnimationSectionProps> = ({
    isAnimationEnabled,
    setIsAnimationEnabled,
    animationType,
    setAnimationType
}) => {
    // Animation options data with labels that include emoji icons
    const ANIMATION_OPTIONS_WITH_EMOJI = [
        { value: 'tada', label: '👋 Tada' },
        { value: 'shake', label: '↔️ Shake' },
        { value: 'swing', label: '🔄 Swing' },
        { value: 'wobble', label: '〰️ Wobble' },
        { value: 'jello', label: '🍮 Jello' },
        { value: 'pulse', label: '💓 Pulse' },
        { value: 'zoom', label: '🔍 Zoom' }
    ];

    return (
        <Fieldset mt="md" mb={'md'}>
            <Flex align="center" mb={16}>
                <Text style={{ flexGrow: 1, fontSize: '15.5px' }} fw={500}>
                    <Box component="span" style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon icon={faPlay} style={{ marginRight: 8 }} />
                        Animation
                    </Box>
                </Text>
                <Switch
                    size="sm"
                    checked={isAnimationEnabled}
                    onChange={(e) => setIsAnimationEnabled(e.currentTarget.checked)}
                />
            </Flex>

            {isAnimationEnabled && (
                <Select
                    label="Animation Type"
                    placeholder="Select an animation"
                    data={ANIMATION_OPTIONS_WITH_EMOJI}
                    value={animationType}
                    onChange={(value) => setAnimationType(value || 'tada')}
                    searchable
                    // nothingFound="No options found"
                    disabled={!isAnimationEnabled}
                />
            )}
        </Fieldset>
    );
};