import { AuthCarousel } from '@/components/Auth/AuthCarousel';
import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Image,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import classes from './auth.module.css';
import { API_URL, APP_ENVIRONMENT } from '@/constants/Constants';
import axios from 'axios';
import { notifications } from '@mantine/notifications';
import { useUserStore } from '@/stores/useUserStore';
import { setAuthTokenLocalStorage } from '@/utils/Storage';
import { useState } from 'react';
import { getAppLogo, getAppPrivacyPolicy, getAppTermsOfService, isWhitelabelDomain } from '@/utils/CommonUtility';
import { useDocumentTitle } from '@mantine/hooks';
import { UserService } from '@/services/UserService';
import { OnboardingModal } from '@/components/Modals/OnboardingModal/OnboardingModal';

export function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [setUser] = useUserStore((state) => [state.setUser]);
  const navigate = useNavigate();
  const [showOnboarding, setShowOnboarding] = useState(false);
  // Check the current domain
  if (window.location.hostname === 'app.replug.io' || window.location.hostname === 'dev.replug.io') {
    useDocumentTitle('Login | Replug');
  } else {
    useDocumentTitle('Login');
  }
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      remember_me: false,
      // get source from query string
      source: new URLSearchParams(window.location.search).get('source') || '',
      // get redirect from query string
      redirect: new URLSearchParams(window.location.search).get('redirect') || '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.trim().length > 0 ? null : 'Password is required'),
    },
  });

  const login = async (formValues: any) => {
    setLoading(true);
    await axios
      .post(`${API_URL}/auth/login`, formValues)
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          if (res.data?.frill_redirect) {
            window.location.href = res.data.frill_redirect;
            return;
          }
          if (isWhitelabelDomain() && !res.data.is_workspace_member) {
            notifications.show({
              color: 'red',
              message: 'You are not added to any Workspace, Please contact admin.',
            });
            return;
          }
          setAuthTokenLocalStorage(res.data.authorisation.token);
          // Update user with last_login_at
          const userData = {
            ...res.data.user,
            last_login_at: new Date().toISOString()
          };
          setUser(userData);
          saveProfile(userData);
          if (!res.data.user.email_verified_at) {
            navigate('/verification');
          } else if (!res.data.is_workspace_member) {
            // Instead of showing notification, open the onboarding modal
            setShowOnboarding(true);
          } else {
            // Get the full query string
            const queryString = window.location.search;
            // Extract the entire redirect URL
            const redirectMatch = queryString.match(/[?&]redirect=([^#]+)/);
            const redirect = redirectMatch ? decodeURIComponent(redirectMatch[1]) : null;

            console.debug("redirect: ", redirect);
            redirect ? navigate(redirect) : navigate('/dashboard');
          }
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
          return;
        }
        notifications.show({
          color: 'red',
          message: 'Something went wrong',
        });
      });
    setLoading(false);
  };

  console.debug(import.meta.env);

  const handleGoogleLogin = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/google`);
      if (response.data.status === 'success' && response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Failed to initiate Google login'
      });
    }
  };

  const saveProfile = async (formValues: any) => {
    setLoading(true);
    await new UserService()
      .updateProfileInformation({
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        last_login_at: formValues?.last_login_at,
      })
      .then((res) => {
        if (res.data.status) {
        }
      })
      .catch((err) => {
        notifications.show({
          color: 'red',
          message: err.response.data.message,
        });
      });
    setLoading(false);
  };

  return (
    <>
      <Paper
        style={{
          backgroundColor: '#f2f6f7',
          minHeight: '100vh', // Ensure full height
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden', // Prevent scrolling
        }}
      >
        <Grid>
          <Grid.Col
            span={3.5}
            style={{
              height: '100%',
            }}
          >
            <Flex mt={16} mx={16} direction="column" className={classes.authContainer}>
              <Box className={classes.authLogoContainer}>
                <Image w={122} h={36} src={getAppLogo()} />
              </Box>
              <Stack h="100%" justify="space-between">
                <Box p={16} px={32}>
                  <Title order={4} ta="center" pt={20} pb={40}>
                    Log in to your account
                  </Title>

                  {/* Add Google Sign In Button */}
                  {/* {APP_ENVIRONMENT !== 'production' && (<> */}

                  <Button
                    variant="outline"
                    fullWidth
                    leftSection={
                      <Image
                        src="https://www.google.com/favicon.ico"
                        width={20}
                        height={20}
                        alt="Google"
                      />
                    }
                    onClick={handleGoogleLogin}
                    mb={24}
                  >
                    Sign in with Google
                  </Button>

                  <Divider
                    label="Or continue with email"
                    labelPosition="center"
                    mb={24}
                  />
                  {/* </>)} */}

                  <form onSubmit={form.onSubmit(login)}>
                    <TextInput
                      mb={16}
                      label="Email"
                      placeholder="e.g john.carl@email.com"
                      {...form.getInputProps('email')}
                    />
                    <PasswordInput
                      mb={16}
                      label="Password"
                      placeholder="e.g ********"
                      {...form.getInputProps('password')}
                    />
                    <Flex mb={24} align="center">
                      <Checkbox
                        size="xs"
                        label="Remember me"
                        {...form.getInputProps('remember_me')}
                      />
                      <Anchor
                        fw={600}
                        size="xs"
                        style={{
                          marginLeft: 'auto',
                        }}
                        onClick={() => navigate('/forgot-password')}
                      >
                        Forgot password?
                      </Anchor>
                    </Flex>
                    <Box
                      mt={40}
                      size="md"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button loading={loading} disabled={loading} type="submit" w={140}>
                        Sign in
                      </Button>
                    </Box>
                    {!isWhitelabelDomain() && (
                      <Flex p={16} pt={30} direction="column" align="center">
                        <Text pb={8} size="sm">
                          Don’t have an account?{' '}
                          <Anchor onClick={() => navigate('/register')} fw="bold">
                            Sign up
                          </Anchor>
                          .
                        </Text>
                      </Flex>
                    )}
                  </form>
                </Box>
                <Flex p={16} direction="column" align="center">
                  <Text pb={16} size="sm" ta="center" c="dimmed">
                    Our{' '}
                    <Anchor onClick={() => window.open(getAppTermsOfService(), '_blank')}>
                      terms of service
                    </Anchor>{' '}
                    and{' '}
                    <Anchor onClick={() => window.open(getAppPrivacyPolicy(), '_blank')}>
                      privacy policy
                    </Anchor>
                    .
                  </Text>
                </Flex>
              </Stack>
            </Flex>
          </Grid.Col>
          <Grid.Col span={8.5}>
            <AuthCarousel />
          </Grid.Col>
        </Grid>
      </Paper>
      <OnboardingModal
        opened={showOnboarding}
        onClose={() => setShowOnboarding(false)} // Empty function since we don't want to allow closing
        isForced={false}
        showAlert={true}
      />
    </>
  );
}
