// components/Bio/CustomAppearanceSection.tsx
import React from 'react';
import {
    Box,
    Fieldset,
    Flex,
    Switch,
    Text,
    ColorInput,
    NumberInput,
    Select,
    Accordion,
    Divider
} from '@mantine/core';
import { faPaintBrush } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomAppearanceProps {
    isCustomAppearanceEnabled: boolean;
    setIsCustomAppearanceEnabled: (value: boolean) => void;
    customAppearance: {
        blockTitleColor: string;
        textColor: string;
        buttonStyle: string;
        buttonBgColor: string;
        buttonTextColor: string;
        buttonBorderRadius: number;
        buttonBorderColor: string;
        blockBgColor: string;
        blockBorderRadius: number;
        blockBorderColor: string;
        buttonShadow: string;
        blockShadow: string;
    };
    setCustomAppearance: (value: any) => void;
    showButtonSection?: boolean;
}

// Button style options
const BUTTON_STYLE_OPTIONS = [
    { value: 'filled', label: 'Filled' },
    { value: 'outline', label: 'Outline' },
    // { value: 'subtle', label: 'Subtle' },
    // { value: 'light', label: 'Light' },
    { value: 'default', label: 'Default' }
];

// Shadow options
const SHADOW_OPTIONS = [
    { value: 'none', label: 'None' },
    { value: 'sm', label: 'Small' },
    { value: 'md', label: 'Medium' },
    { value: 'lg', label: 'Large' },
    { value: 'xl', label: 'Extra Large' }
];

export const CustomAppearanceSection: React.FC<CustomAppearanceProps> = ({
    isCustomAppearanceEnabled,
    setIsCustomAppearanceEnabled,
    customAppearance,
    setCustomAppearance,
    showButtonSection = false
}) => {
    // Helper function to update a specific property
    const updateAppearance = (key: string, value: any) => {
        setCustomAppearance({
            ...customAppearance,
            [key]: value
        });
    };

    return (
        <Fieldset mt="md">
            <Flex align="center" mb={16}>
                <Text style={{ flexGrow: 1, fontSize: '15.5px' }} fw={500}>
                    <Box component="span" style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon icon={faPaintBrush} style={{ marginRight: 8 }} />
                        Custom Appearance
                    </Box>
                </Text>
                <Switch
                    size="sm"
                    checked={isCustomAppearanceEnabled}
                    onChange={(e) => setIsCustomAppearanceEnabled(e.currentTarget.checked)}
                />
            </Flex>

            {isCustomAppearanceEnabled && (
                <Accordion defaultValue="block" variant="separated">
                    <Accordion.Item value="block">
                        <Accordion.Control>Block Appearance</Accordion.Control>
                        <Accordion.Panel>
                            <Flex direction="column" gap="md">
                                <ColorInput
                                    label="Title Color"
                                    placeholder="Pick a color"
                                    format="rgba"
                                    withEyeDropper
                                    swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                                    value={customAppearance.blockTitleColor}
                                    onChange={(value) => updateAppearance('blockTitleColor', value)}
                                />

                                <ColorInput
                                    label="Text Color"
                                    placeholder="Pick a color"
                                    format="rgba"
                                    withEyeDropper
                                    swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                                    value={customAppearance.textColor}
                                    onChange={(value) => updateAppearance('textColor', value)}
                                />

                                <ColorInput
                                    label="Background Color"
                                    placeholder="Pick a color"
                                    format="rgba"
                                    withEyeDropper
                                    swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                                    value={customAppearance.blockBgColor}
                                    onChange={(value) => updateAppearance('blockBgColor', value)}
                                />

                                <NumberInput
                                    label="Border Radius"
                                    placeholder="Enter radius in px"
                                    min={10}
                                    max={500}
                                    value={customAppearance.blockBorderRadius}
                                    onChange={(value) => updateAppearance('blockBorderRadius', value)}
                                />

                                <ColorInput
                                    label="Border Color"
                                    placeholder="Pick a color"
                                    format="rgba"
                                    withEyeDropper
                                    swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                                    value={customAppearance.blockBorderColor}
                                    onChange={(value) => updateAppearance('blockBorderColor', value)}
                                />

                                <Select
                                    label="Shadow"
                                    placeholder="Select shadow"
                                    data={SHADOW_OPTIONS}
                                    value={customAppearance.blockShadow}
                                    onChange={(value) => updateAppearance('blockShadow', value || 'none')}
                                />
                            </Flex>
                        </Accordion.Panel>
                    </Accordion.Item>

                    {showButtonSection && (
                        <Accordion.Item value="button">
                            <Accordion.Control>Button Appearance</Accordion.Control>
                            <Accordion.Panel>
                                <Flex direction="column" gap="md">
                                    <Select
                                        label="Style"
                                        placeholder="Select style"
                                        data={BUTTON_STYLE_OPTIONS}
                                        value={customAppearance.buttonStyle}
                                        onChange={(value) => updateAppearance('buttonStyle', value)}
                                    />

                                    <ColorInput
                                        label="Background Color"
                                        placeholder="Pick a color"
                                        format="rgba"
                                        withEyeDropper
                                        swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                                        value={customAppearance.buttonBgColor}
                                        onChange={(value) => updateAppearance('buttonBgColor', value)}
                                    />

                                    <ColorInput
                                        label="Color"
                                        placeholder="Pick a color"
                                        format="rgba"
                                        withEyeDropper
                                        swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                                        value={customAppearance.buttonTextColor}
                                        onChange={(value) => updateAppearance('buttonTextColor', value)}
                                    />

                                    <NumberInput
                                        label="Border Radius"
                                        placeholder="Enter radius in px"
                                        min={0}
                                        max={50}
                                        value={customAppearance.buttonBorderRadius}
                                        onChange={(value) => updateAppearance('buttonBorderRadius', value)}
                                    />

                                    <ColorInput
                                        label="Border Color"
                                        placeholder="Pick a color"
                                        format="rgba"
                                        withEyeDropper
                                        swatches={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']}
                                        value={customAppearance.buttonBorderColor}
                                        onChange={(value) => updateAppearance('buttonBorderColor', value)}
                                    />

                                    <Select
                                        label="Shadow"
                                        placeholder="Select shadow"
                                        data={SHADOW_OPTIONS}
                                        value={customAppearance.buttonShadow}
                                        onChange={(value) => updateAppearance('buttonShadow', value)}
                                    />
                                </Flex>
                            </Accordion.Panel>
                        </Accordion.Item>
                    )}
                </Accordion>
            )}
        </Fieldset>
    );
};