import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { useBioSocialBlockStore } from '@/stores/Bio/useBioSocialBlockStore';
import { useBioVideoBlockStore } from '@/stores/Bio/useBioVideoBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faCode, faEllipsis, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Fieldset,
  Flex,
  Grid,
  Group,
  Slider,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  DEFAULT_BIO_VIDEO_BLOCK_VALUES,
  VIDEO_NETWORKS,
  findVideoNetworkByValue,
  findVideoNetworkPlaceholderByValue,
} from '@replug/utils';
import { useParams } from 'react-router-dom';
import { ScheduleSection } from '../ScheduleSection';
import { useEffect, useState } from 'react';
import { AnimationSection } from '../AnimationSection';
import { CustomAppearanceSection } from '../CustomAppearanceSection';

export const BioVideoSection = ({ reload }: { reload: () => void }) => {
  // get bio link id from the url

  const { id } = useParams();

  // workspace store

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  // import store for the bio video block

  const [bioVideoBlock, setBioVideoBlock, resetBioVideoBlock] = useBioVideoBlockStore((state) => [
    state.bioVideoBlock,
    state.setBioVideoBlock,
    state.reset,
  ]);

  // set the video selection
  const handleVideoSelection = (value: string) => {
    setBioVideoBlock({ ...bioVideoBlock, video_network: value });
  };

  // get the bio link from the store

  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

  // Schedule state variables
  const [isScheduleEnabled, setIsScheduleEnabled] = useState<boolean>(bioVideoBlock.is_schedule_enabled || false);
  const [startDateTime, setStartDateTime] = useState<Date | null>(
    bioVideoBlock.is_schedule_enabled && bioVideoBlock.schedule_start_at ? new Date(bioVideoBlock.schedule_start_at) : null
  );
  const [endDateTime, setEndDateTime] = useState<Date | null>(
    bioVideoBlock.schedule_end_at ? new Date(bioVideoBlock.schedule_end_at) : null
  );
  const [timezone, setTimezone] = useState<string>(bioVideoBlock.schedule_timezone || 'UTC');
  // Animation state variables
  const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(bioVideoBlock.is_animation_enabled || false);
  const [animationType, setAnimationType] = useState<string>(bioVideoBlock.animation_type || 'tada');
  // Custom Appearance state variables
  const [isCustomAppearanceEnabled, setIsCustomAppearanceEnabled] = useState<boolean>(
    bioVideoBlock.is_custom_appearance_enabled || false
  );
  const [customAppearance, setCustomAppearance] = useState(
    bioVideoBlock.custom_appearance_attributes || DEFAULT_BIO_VIDEO_BLOCK_VALUES.custom_appearance_attributes
  );

  // Add block to the bio link
  const handleAddBlock = () => {
    if (bioVideoBlock.video_url.length === 0) {
      notifications.show({
        color: 'red',
        message: 'Please add video URL.',
      });
      return;
    }
    if (bioVideoBlock.is_show_title_enabled && bioVideoBlock.title.length === 0) {
      notifications.show({
        color: 'red',
        message: 'Please add title for the block.',
      });
      return;
    }

    if (bioVideoBlock.video_title.length === 0) {
      notifications.show({
        color: 'red',
        message: 'Please add video title.',
      });
      return;
    }

    let payload = {
      ...bioVideoBlock,
      bio_link_id: id,
      workspace_id: defaultWorkspace?._id,
      is_schedule_enabled: isScheduleEnabled,
      schedule_start_at: startDateTime ? startDateTime.toISOString() : null,
      schedule_end_at: endDateTime ? endDateTime.toISOString() : null,
      schedule_timezone: timezone,
      // Animation fields
      is_animation_enabled: isAnimationEnabled,
      animation_type: animationType,
      // Custom Appearance fields
      is_custom_appearance_enabled: isCustomAppearanceEnabled,
      custom_appearance_attributes: customAppearance
    };

    if (bioVideoBlock._id) {
      new BioLinkBlockService()
        .update(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            resetBioVideoBlock();
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
          if ((err.response.status === 400 || err.response.status === 422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      payload.order = bioLink?.blocks.length + 1;

      if (!bioLink?._id) {
        notifications.show({
          color: 'red',
          message: 'Please first publish your bio link.',
        });
        return;
      }
      new BioLinkBlockService()
        .create(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });
            resetBioVideoBlock();
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
          if ((err.response.status === 400 || err.response.status === 422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }
  };

  // Update schedule-related props when schedule settings change
  useEffect(() => {
    setBioVideoBlock({
      ...bioVideoBlock,
      is_schedule_enabled: isScheduleEnabled,
      schedule_start_at: isScheduleEnabled ? (startDateTime || new Date()).toISOString() : null,
      schedule_end_at: isScheduleEnabled ? (endDateTime?.toISOString() || null) : null,
      schedule_timezone: isScheduleEnabled ? timezone : 'UTC'
    });
  }, [isScheduleEnabled, startDateTime, endDateTime, timezone]);

  // Update animation-related props when animation settings change
  useEffect(() => {
    setBioVideoBlock({
      ...bioVideoBlock,
      is_animation_enabled: isAnimationEnabled,
      animation_type: animationType
    });
  }, [isAnimationEnabled, animationType]);

  // Update custom appearance-related props when custom appearance settings change
  useEffect(() => {
    setBioVideoBlock({
      ...bioVideoBlock,
      is_custom_appearance_enabled: isCustomAppearanceEnabled,
      custom_appearance_attributes: customAppearance
    });
  }, [isCustomAppearanceEnabled, customAppearance]);

  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Add video link to your profile with the Video Block. Use this block to add engaging video
        content.
      </Text>
      <Divider color="gray.2" />
      <Box p="md">
        <Flex mb={'md'} direction={'column'}>
          <Flex
            align={'flex-end'}
            justify={'end'}
            style={{
              flexGrow: 1,
            }}
          >
            <Switch
              size="xs"
              label="Show title"
              checked={bioVideoBlock.is_show_title_enabled}
              onChange={(e) => {
                setBioVideoBlock({
                  ...bioVideoBlock,
                  is_show_title_enabled: e.currentTarget.checked,
                });
              }}
            />
          </Flex>
          <TextInput
            w={'100%'}
            value={bioVideoBlock.title}
            onChange={(e) => {
              setBioVideoBlock({ ...bioVideoBlock, title: e.currentTarget.value });
            }}
            label="Add a title"
            placeholder="Your title for the video block"
            style={{
              wordBreak: 'break-word',
              whiteSpace: 'normal',
            }}
          />
        </Flex>
        <Fieldset mb="md">
          <Text fz={14} fw={500} mb={8}>
            Supported networks
          </Text>
          <Group mt="md">
            {VIDEO_NETWORKS.map((network) => (
              <Tooltip label={network.name}>
                <ActionIcon
                  variant="outline"
                  color={bioVideoBlock.video_network === network.value ? 'primary' : 'gray.6'}
                  onClick={() => {
                    handleVideoSelection(network.value);
                  }}
                >
                  {network.icon}
                </ActionIcon>
              </Tooltip>
            ))}
            {/* <Tooltip label="Custom">
              <ActionIcon variant="outline" color="gray.6">
                <FontAwesomeIcon icon={faEllipsis} />
              </ActionIcon>
            </Tooltip> */}
          </Group>

          <TextInput
            my={'md'}
            label="URL"
            value={bioVideoBlock.video_url}
            onChange={(e) => {
              let videoNetwork = bioVideoBlock.video_network;
              if (
                e.currentTarget.value.includes('youtube.com') ||
                e.currentTarget.value.includes('youtu.be')
              ) {
                videoNetwork = 'youtube';
              } else if (e.currentTarget.value.includes('tiktok.com')) {
                videoNetwork = 'tiktok';
              } else if (e.currentTarget.value.includes('facebook.com')) {
                videoNetwork = 'facebook';
              } else if (e.currentTarget.value.includes('instagram.com')) {
                videoNetwork = 'instagram';
              } else if (
                e.currentTarget.value.includes('twitter.com') ||
                e.currentTarget.value.includes('x.com')
              ) {
                videoNetwork = 'twitter';
              }
              setBioVideoBlock({
                ...bioVideoBlock,
                video_url: e.currentTarget.value,
                video_network: videoNetwork,
              });
            }}
            required
            placeholder={findVideoNetworkPlaceholderByValue(bioVideoBlock.video_network)}
            leftSection={<>{findVideoNetworkByValue(bioVideoBlock.video_network)?.icon}</>}
          />

          <TextInput
            value={bioVideoBlock.video_title}
            onChange={(e) => {
              setBioVideoBlock({ ...bioVideoBlock, video_title: e.currentTarget.value });
            }}
            label="Title"
            placeholder="Add a title"
          />

          <Grid my="xl">
            <Grid.Col
              span={5.5}
              p={'md'}
              mx={8}
              style={{
                borderRadius: 4,
                border: '1px solid var(--mantine-color-gray-2)',
              }}
            >
              <Flex align={'center'} mb="md">
                <Text
                  fz={14}
                  fw={500}
                  mb={8}
                  style={{
                    flexGrow: 1,
                  }}
                >
                  Custom Height
                </Text>
                <Switch
                  size="xs"
                  checked={bioVideoBlock.is_custom_height_enabled}
                  onChange={(e) => {
                    setBioVideoBlock({
                      ...bioVideoBlock,
                      is_custom_height_enabled: e.currentTarget.checked,
                    });
                  }}
                />
              </Flex>
              <Slider
                mb={'md'}
                color="primary"
                min={100}
                step={1}
                max={800}
                value={bioVideoBlock.height}
                marks={
                  [
                    { value: 150, label: '150' },
                    { value: 300, label: '300' },
                    { value: 500, label: '500' },
                    { value: 700, label: '700' },
                  ] as any[]
                }
                defaultValue={bioVideoBlock.height}
                onChange={(value) => {
                  setBioVideoBlock({ ...bioVideoBlock, height: value });
                }}
              />
            </Grid.Col>
          </Grid>
        </Fieldset>

        {/* Schedule Section */}
        <ScheduleSection
          isScheduleEnabled={isScheduleEnabled}
          setIsScheduleEnabled={setIsScheduleEnabled}
          startDateTime={startDateTime}
          setStartDateTime={setStartDateTime}
          endDateTime={endDateTime}
          setEndDateTime={setEndDateTime}
          timezone={timezone}
          setTimezone={setTimezone}
        />

        {/* Animation Section */}
        <AnimationSection
          isAnimationEnabled={isAnimationEnabled}
          setIsAnimationEnabled={setIsAnimationEnabled}
          animationType={animationType}
          setAnimationType={setAnimationType}
        />

        {/* Custom Appearance Section */}
        <CustomAppearanceSection
          isCustomAppearanceEnabled={isCustomAppearanceEnabled}
          setIsCustomAppearanceEnabled={setIsCustomAppearanceEnabled}
          customAppearance={customAppearance}
          setCustomAppearance={setCustomAppearance}
        />

        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioVideoBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};
