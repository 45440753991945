import { usePlanStore } from "@/stores/usePlanStore";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import { getRelativeTime } from "@/utils/DateUtility";
import { Button, Checkbox, CloseButton, Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const BillingBanner = () => {

  const [plan, setUpgradeModalState, primarySubscription] = usePlanStore((state) => [state.plan, state.setUpgradeModalState, state.primarySubscription]);
  const navigate = useNavigate();
  // Existing state...
  const [showLTDBanner, setShowLTDBanner] = useState(true);
  const [permanentDismiss, setPermanentDismiss] = useState(false);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace])

  const is_subscription_paused = plan?.is_subscription_paused && plan?.paused_from
  const is_past_due = defaultWorkspace?.organization?.status === 'past_due'
  const next_payment_attempt = primarySubscription?.next_bill_date
  const is_subscription_cancelled = defaultWorkspace?.organization?.status === 'cancelled' || defaultWorkspace?.organization?.status === 'deleted' || defaultWorkspace?.organization?.status === 'disabled' || defaultWorkspace?.organization?.status === 'trial_finished';

  const isUserOnGracePeriod = () => {
    const cancellationEffectiveDate = defaultWorkspace?.organization?.cancellation_effective_at;
    if (cancellationEffectiveDate && cancellationEffectiveDate > new Date().toISOString() && defaultWorkspace?.organization?.on_grace_period) {
      return true;
    }
    return false;
  }

  const getCancellationEffectiveDate = () => {
    return new Date(defaultWorkspace?.organization?.cancellation_effective_at).toDateString();
  }

  const getPausedFromDate = () => {
    return new Date(plan?.paused_from || '').toDateString();
  }

  const openPaddleUrl = (url: string) => {
    // @ts-ignore
    Paddle.Checkout.open({
      override: url,
      successCallback: function (data: any) {
        notifications.show({
          message: 'Billing details updated successfully',
          color: 'green',
        });
      },
    });
  };

  // Check localStorage on component mount
  useEffect(() => {
    const isLTDBannerDismissed = localStorage.getItem('ltd_banner_dismissed');
    if (isLTDBannerDismissed === 'true') {
      setShowLTDBanner(false);
    }
  }, []);

  const handleBannerClose = () => {
    setShowLTDBanner(false);
    if (permanentDismiss) {
      localStorage.setItem('ltd_banner_dismissed', 'true');
    }
  };

  return (
    is_past_due ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        Your subscription is past due. We were unable to charge your credit card. We'll reattempt on {new Date(next_payment_attempt).toLocaleDateString()}. Please update your
        <Button
          variant="default"
          size="md"
          onClick={() => openPaddleUrl(primarySubscription?.update_url)}
          ml={8}
          styles={{
            root: {
              border: 'none',
              textDecoration: 'underline',
              backgroundColor: 'transparent',
              padding: 0,
              height: 'auto',

              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'none'
              }
            }
          }}
        >
          billing details
        </Button>
      </Flex>
    ) : plan?.type_of === 'trial' ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        Your trial plan ends {getRelativeTime(plan?.trial_ends_at)}
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            setUpgradeModalState(true);
            navigate('/account/billing');
          }}
          style={{ marginLeft: '8px' }}
        >
          Upgrade Now
        </Button>
      </Flex>
    ) : isUserOnGracePeriod() ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        Your subscription has been cancelled. You will lose access to Replug on {getCancellationEffectiveDate()}.
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            setUpgradeModalState(true);
            navigate('/account/billing');
          }}
          style={{ marginLeft: '8px' }}
        >
          Renew Now
        </Button>
      </Flex>
    ) : is_subscription_paused ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        Your subscription has been paused. You will lose access to Replug on {getPausedFromDate()}.
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            navigate('/account/billing');
          }}
          style={{ marginLeft: '8px' }}
        >
          View Plan
        </Button>
      </Flex>
      ) : plan?.type_of === 'lifetime' ? (
      showLTDBanner ? (
        <Flex
          justify={'center'}
          align={'center'}
          style={{
            padding: '8px',
            fontSize: '15px',
            fontWeight: '500',
            position: 'relative', // For positioning close button
          }}
        >
          <Flex align="center" style={{ width: '100%', justifyContent: 'center' }}>
            Upgrade LTD Plan - 50% Discount on Monthly/Yearly Subscriptions
            <Button
              variant="outline"
              size="xs"
              color="green"
              onClick={() => {
                setUpgradeModalState(true);
                navigate('/account/billing');
              }}
              style={{ marginLeft: '8px' }}
            >
              Upgrade Now
            </Button>

            <Flex
              align="center"
              style={{
                position: 'absolute',
                right: '16px',
                gap: '8px'
              }}
            >
              <Checkbox
                size="xs"
                label="Hide forever"
                checked={permanentDismiss}
                onChange={(event) => setPermanentDismiss(event.currentTarget.checked)}
                styles={{
                  label: {
                    color: 'var(--mantine-color-gray-7)'
                  }
                }}
              />
              <CloseButton
                size="sm"
                onClick={handleBannerClose}
              />
            </Flex>
          </Flex>
        </Flex>
      ) : null
    ) : is_subscription_cancelled ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        Your account has been {defaultWorkspace?.organization?.status || 'cancelled'}. Please upgrade your plan to continue using Replug.
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            setUpgradeModalState(true);
            navigate('/account/billing');
          }}
          style={{ marginLeft: '8px' }}
        >
          Upgrade Now
        </Button>
      </Flex>
    ) : null
  );
}
